import Button from 'components/Button';
import Modal, { ModalActions, ModalContent, ModalTitle } from 'components/Modal';
import { MarketItem } from 'pages/MarketplaceItem/MarketplaceItem';
import * as S from './CheckoutModal.styles';

interface Props {
  isOpen: boolean;
  item: MarketItem;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CheckoutModal = ({ isOpen, item, loading, onClose, onConfirm }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Checkout</ModalTitle>
      <ModalContent>
        <S.Content>
          <S.ItemWrapper>
            <S.Image src={item.imgSrc} />
            <div>
              <S.Owner>{item.owner}</S.Owner>
              <S.ItemName>{item.title}</S.ItemName>
            </div>
          </S.ItemWrapper>
          <S.Price>{item.price} ETH</S.Price>
        </S.Content>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button onClick={onConfirm} loading={loading}>
          Complete
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default CheckoutModal;
