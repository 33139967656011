import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ noPadding?: boolean }>`
  ${({ noPadding }) =>
    noPadding
      ? css`
          padding: 0;
        `
      : containerCss};
`;

export const containerCss = css`
  padding: 40px;
  /* margin-left: 80px; */

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 20px 10px;
  }
`;
