// import { useQuery } from 'react-query';
import ReactMarkdown from 'react-markdown';
import { useMemo } from 'react';

// import { fetchFaqList } from 'services/faq';
import Loader from 'components/Loader';
import * as S from './Faq.styles';

const data = [
  {
    id: 1,
    attributes: {
      title: 'How do I buy?',
      answer:
        'The Tickets to the Moon NFT series will launch on Opensea. Make sure to whitelist for the drop on Discord.',
    },
  },
  {
    id: 2,
    attributes: {
      title: 'When do I buy?',
      answer:
        'We will announce the date of the NFT drop closer to the rocket launch next month. Join the Discord to stay tuned.',
    },
  },
  {
    id: 3,
    attributes: {
      title: 'How to join the community?',
      answer: 'To join Discord, go here. To join Telegram, go here. To join Twitter, go here.',
    },
  },
  {
    id: 4,
    attributes: {
      title: 'What are the NFT rarities?',
      answer:
        'There are four Ticket types - Bronze, Silver, Gold, Legendary. Each comes with different access to prizes and experiences. For more information see the official landing page site here.',
    },
  },
  {
    id: 5,
    attributes: {
      title: 'How do the prizes work?',
      answer:
        'After the NFT drop, Ticket holders of different rarities will participate in prize lotteries. Various prizes and in-person experiences will be lotteried and the lucky winners of these rewards will be announced on our Discord. Some lotteries will happen automatically and others will require you signing up and connecting your wallet that holds your Ticket.',
    },
  },
  {
    id: 6,
    attributes: {
      title: 'As a Ticket holder, how can I have the best shot of winning?',
      answer:
        'Each prize is a lottery, so every ticket holder has an equal shot at winning. The more Tickets that you hold the better your chances. All lottery code for winning prizes and in-person experiences will be in an open source smart contract.',
    },
  },
  {
    id: 7,
    attributes: {
      title: 'If I win, what happens then?',
      answer:
        'If you are one of the winners that we announce in the Discord, we will ask that you dm us. From there our prizes team will facilitate either delivering/mailing your prize if it is an item, or walking you through the in-person experience that you won. Please note that for some in-person experiences, you must KYC first and residents from certain countries may not be able to redeem. You can always transfer your prize or experience pass to someone else.',
    },
  },
  {
    id: 8,
    attributes: {
      title: 'Will I receive a physical ticket?',
      answer:
        'If you submit your address with postage fees, you will get a 1-of-1 physical Ticket mailed to you printed with your exact Edition number. This is the physical version of your NFT flying to the Moon.',
    },
  },
];

const Faq = () => {
  const isLoading = false;
  // const { data, isLoading } = useQuery('faq', () => fetchFaqList({}));

  const middleIndex = Math.ceil((data?.length || 0) / 2);

  const leftPart = useMemo(() => data?.slice(0, middleIndex), [middleIndex]);
  const rightPart = useMemo(() => data?.slice(middleIndex, data?.length), [middleIndex]);

  return (
    <S.Wrapper>
      <S.Title>FAQ</S.Title>

      {isLoading ? (
        <Loader />
      ) : (
        <S.Content>
          <S.LeftSide>
            {leftPart?.map(({ id, attributes: { title, answer } }) => {
              return (
                <S.FaqItem key={title} title={title} content={<ReactMarkdown>{answer}</ReactMarkdown>} />
              );
            })}
          </S.LeftSide>

          <S.RightSide>
            {rightPart?.map(({ id, attributes: { title, answer } }) => {
              return (
                <S.FaqItem key={title} title={title} content={<ReactMarkdown>{answer}</ReactMarkdown>} />
              );
            })}
          </S.RightSide>
        </S.Content>
      )}
    </S.Wrapper>
  );
};

export default Faq;
