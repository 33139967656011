import { ReactNode } from 'react';
import * as S from './Card.styles';

export interface CardProps {
  title: string;
  description?: ReactNode;
  imgSrc?: string;
  endSlot?: ReactNode;
  className?: string;
  contentClassName?: string;
  imgClassName?: string;
  descClassName?: string;
  crop?: boolean;
  bg?: string;
  onClick?: () => void;
}
const Card = ({
  title,
  description,
  imgSrc,
  endSlot,
  className,
  contentClassName,
  descClassName,
  imgClassName,
  crop,
  bg,
  onClick,
}: CardProps) => {
  return (
    <S.Wrapper className={className} onClick={onClick}>
      {bg && (
        <div
          style={{
            background: `linear-gradient(-215deg, #c5a0dd 0%, ${bg} 100%)`,
            height: '100%',
            width: '100%',
            position: 'absolute',
            borderRadius: 8,
          }}
        ></div>
      )}
      <S.Image className={imgClassName} src={imgSrc} crop={crop}>
        {!imgSrc && <S.HelperText>No image</S.HelperText>}
      </S.Image>
      <S.Content className={contentClassName}>
        <S.Title>{title}</S.Title>
        {description && <S.Description className={descClassName}>{description}</S.Description>}
        {endSlot}
      </S.Content>
    </S.Wrapper>
  );
};

export default Card;
