import { useNetwork, chain as chainInfo } from 'wagmi';

import * as S from './ChainInfo.styles';

interface Props {
  className?: string;
}

const ChainInfo = ({ className }: Props) => {
  const { chain } = useNetwork();

  const isMainnet = chain?.id === chainInfo.polygon.id;
  const isTestnet = chain?.id === chainInfo.polygonMumbai.id || chain?.id === chainInfo.goerli.id;

  if (!chain || isMainnet) {
    return null;
  }

  return (
    <S.Warning error={!isTestnet} className={className}>
      <S.InfoIcon icon="info" />
      {isTestnet ? `Testnet (${chain.name})` : `Chain ID ${chain?.id} is not supported`}
    </S.Warning>
  );
};

export default ChainInfo;
