import styled, { css } from 'styled-components';

export const Tab = styled.button<{ selected?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 10px;
  height: 48px;
  padding: 0 22px;
  color: ${({ theme }) => theme.colors.white};
  background-color: transparent;
  outline: none;
  font-family: 'LEMON MILK';
  cursor: pointer;
  transition: background-color 0.15s linear;

  &:last-child {
    margin-right: 0;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.primary.main};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const TabList = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
