// import cardSmall from 'assets/images/home/cardSmall.png';
// import cardMedium from 'assets/images/home/cardMedium.png';
// import cardLarge from 'assets/images/home/cardLarge.png';
import Button from 'components/Button';
import * as S from './About.styles';

const About = () => {
  return (
    <S.Wrapper>
      {/* <S.Imagery>
        <S.CardSmall src={cardSmall} />
        <S.CardMedium src={cardMedium} />
        <S.CardLarge src={cardLarge} />
      </S.Imagery> */}

      <S.Imagery>
        <S.TicketsImage />
      </S.Imagery>

      <S.Content>
        <S.Suptitle>The Tickets</S.Suptitle>
        <S.SectionTitle>About the Tickets</S.SectionTitle>

        <S.Text>
          Each Ticket to the Moon is a unique editioned piece of history. These are the first NFTs that are
          being sent to the Moon where they will live forever on the lunar surface. You will receive a
          physical copy of your ticket as a memento, mailed to your home. <br />
          The Tickets are a special collection because they endow their owners with the ability to earn
          incredible, once-in-a-lifetime prizes and experiences centered around launch. Each prize is
          lotteried to all Ticket holders.
        </S.Text>

        <S.ButtonBlock>
          <Button variant="gradient" onClick={() => window.open('https://discord.gg/ZdRHKN4m', '_blank')}>
            Join Discord
          </Button>
          <Button variant="outline" onClick={() => window.open('https://twitter.com/CrisiumSpace', '_blank')}>
            Follow on X
          </Button>
        </S.ButtonBlock>
      </S.Content>
    </S.Wrapper>
  );
};

export default About;
