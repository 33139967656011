import { IconProps } from '../types';

const ArrowRight = ({ width = 168, height = 40, ...props }: IconProps) => {
  return (
    <svg
      viewBox="0 0 168 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <g clipPath="url(#clip0_422_7349)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.5937 6.14081L34.8042 7.4133C34.9701 8.59706 35.0469 9.79161 35.0341 10.9869C35.0327 13.6283 34.7141 16.2599 34.085 18.8253C33.2343 22.3106 31.7525 25.6106 29.7131 28.562C27.3564 31.9516 23.9488 35.2855 18.9818 37.9643L18.3741 38.2918L17.7665 37.9643C12.7995 35.2855 9.39332 31.9516 7.03523 28.562C4.9958 25.6106 3.51405 22.3106 2.66328 18.8253C2.03435 16.2604 1.7157 13.6292 1.71419 10.9883C1.70135 9.793 1.77822 8.59845 1.94415 7.41469L2.15459 6.1422C2.17255 6.03843 2.1698 5.93212 2.1465 5.82941C2.1232 5.72671 2.0798 5.62963 2.0188 5.54377C1.95781 5.45791 1.88043 5.38497 1.79112 5.32916C1.70181 5.27334 1.60234 5.23575 1.49844 5.21856C1.39454 5.20136 1.28827 5.2049 1.18574 5.22897C1.08321 5.25303 0.986463 5.29716 0.901064 5.35879C0.815665 5.42042 0.743307 5.49834 0.688159 5.58806C0.633011 5.67779 0.596163 5.77754 0.57974 5.88157L0.369296 7.15406C0.188284 8.4239 0.103957 9.70565 0.117041 10.9883C0.118044 13.7593 0.452135 16.5201 1.11212 19.2114C2.01052 22.8866 3.57448 26.3661 5.72657 29.4777C8.21428 33.0583 11.8225 36.5789 17.0153 39.3733L18.002 39.9043C18.1183 39.9672 18.2483 40.0001 18.3804 40.0001C18.5125 40.0001 18.6426 39.9672 18.7588 39.9043L19.7441 39.3733C24.9314 36.5775 28.541 33.0583 31.0329 29.4777C33.1839 26.3663 34.7469 22.8873 35.6445 19.2128C36.3045 16.5215 36.6386 13.7607 36.6396 10.9897C36.6527 9.70705 36.5683 8.4253 36.3874 7.15546L36.1769 5.88297C36.1605 5.77893 36.1236 5.67918 36.0685 5.58946C36.0134 5.49973 35.941 5.42181 35.8556 5.36018C35.7702 5.29855 35.6734 5.25443 35.5709 5.23036C35.4684 5.20629 35.3621 5.20276 35.2582 5.21995C35.1543 5.23715 35.0548 5.27473 34.9655 5.33055C34.8762 5.38636 34.7988 5.4593 34.7379 5.54516C34.6769 5.63102 34.6335 5.7281 34.6102 5.83081C34.5869 5.93352 34.5841 6.03982 34.6021 6.1436L34.5937 6.14081V6.14081Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.51558 5.2739L18.3761 1.65018L30.5014 5.35195C30.7044 5.41264 30.9232 5.39044 31.1099 5.29021C31.2965 5.18998 31.4359 5.01989 31.4974 4.81715C31.559 4.61441 31.5377 4.39555 31.4383 4.20845C31.3389 4.02135 31.1694 3.88126 30.9669 3.81884L18.6088 0.0459916C18.4572 -0.000490101 18.295 -0.000490101 18.1433 0.0459916L6.04869 3.738C5.94761 3.76822 5.85351 3.8181 5.77176 3.88478C5.69002 3.95147 5.62226 4.03364 5.57235 4.12658C5.52245 4.21952 5.4914 4.32141 5.48097 4.42638C5.47055 4.53136 5.48096 4.63736 5.51161 4.7383C5.54226 4.83925 5.59254 4.93314 5.65957 5.0146C5.7266 5.09606 5.80906 5.16347 5.90222 5.21298C5.99537 5.26248 6.09739 5.2931 6.2024 5.30308C6.30742 5.31305 6.41338 5.30219 6.51419 5.27111L6.51558 5.2739V5.2739Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.658965 6.37496L17.6629 39.5629C17.7292 39.6926 17.8298 39.8017 17.9537 39.8782C18.0776 39.9548 18.2201 39.996 18.3658 39.9973C18.5114 39.9986 18.6546 39.96 18.7799 39.8856C18.9051 39.8112 19.0076 39.7039 19.0762 39.5754L29.4458 20.1814L29.4611 20.1508L36.0983 6.35266C36.1882 6.16224 36.1994 5.94401 36.1293 5.74541C36.0593 5.54681 35.9137 5.38388 35.7241 5.29206C35.5346 5.20024 35.3165 5.18693 35.1172 5.25504C34.9179 5.32315 34.7536 5.46717 34.6599 5.65578L28.0339 19.4427L18.3974 37.4778L2.07921 5.64463C2.03218 5.55012 1.96687 5.46587 1.88704 5.39678C1.80722 5.32769 1.71448 5.27512 1.61419 5.24213C1.51391 5.20913 1.40808 5.19637 1.30282 5.20457C1.19757 5.21278 1.09499 5.24179 1.00103 5.28992C0.907066 5.33805 0.823591 5.40435 0.755437 5.48498C0.687284 5.5656 0.635808 5.65895 0.603992 5.75961C0.572176 5.86028 0.560652 5.96625 0.570088 6.0714C0.579525 6.17655 0.609734 6.27878 0.658965 6.37217V6.37496Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.79864 21.1549H27.7074C27.919 21.1549 28.1219 21.0709 28.2716 20.9212C28.4212 20.7716 28.5053 20.5687 28.5053 20.357C28.5053 20.1454 28.4212 19.9425 28.2716 19.7928C28.1219 19.6432 27.919 19.5591 27.7074 19.5591H8.79864C8.58702 19.5591 8.38407 19.6432 8.23443 19.7928C8.0848 19.9425 8.00073 20.1454 8.00073 20.357C8.00073 20.5687 8.0848 20.7716 8.23443 20.9212C8.38407 21.0709 8.58702 21.1549 8.79864 21.1549V21.1549ZM61.5401 14.2699H49.2391C49.0265 14.271 48.8229 14.356 48.6726 14.5063C48.5223 14.6566 48.4374 14.8601 48.4363 15.0727V24.9459C48.4377 25.1583 48.5228 25.3617 48.673 25.5119C48.8232 25.6622 49.0266 25.7472 49.2391 25.7487H61.5401V27.4211H49.2391C48.9145 27.4226 48.5929 27.3598 48.2927 27.2363C47.9926 27.1127 47.7199 26.931 47.4904 26.7015C47.2609 26.472 47.0792 26.1993 46.9556 25.8992C46.8321 25.599 46.7693 25.2774 46.7708 24.9529V15.0797C46.7693 14.7551 46.8321 14.4335 46.9556 14.1334C47.0792 13.8332 47.2609 13.5605 47.4904 13.331C47.7199 13.1015 47.9926 12.9198 48.2927 12.7963C48.5929 12.6727 48.9145 12.6099 49.2391 12.6114H61.5401V14.2699V14.2699ZM84.6384 12.603V14.2755H72.7917V19.1633H82.3136V20.8497H72.7903V25.7459H84.637V27.4184H71.1178V12.603H84.6384Z"
          fill="white"
        />
        <path
          d="M109.033 15.0922V19.4323C109.035 19.7568 108.972 20.0783 108.848 20.3784C108.724 20.6784 108.543 20.9511 108.313 21.1805C108.084 21.41 107.811 21.5917 107.511 21.7153C107.211 21.8389 106.889 21.9019 106.565 21.9006H104.303L108.951 27.4128H106.75L102.122 21.9006H96.6862C96.4174 21.8869 96.1494 21.8595 95.8834 21.8184V27.4128H94.2109V12.6239H106.552C106.877 12.6226 107.198 12.6856 107.498 12.8092C107.799 12.9328 108.071 13.1145 108.301 13.344C108.53 13.5735 108.712 13.8461 108.835 14.1461C108.959 14.4462 109.022 14.7677 109.021 15.0922H109.033V15.0922ZM96.6918 20.2142H106.565C106.668 20.2155 106.77 20.1962 106.866 20.1573C106.961 20.1185 107.048 20.061 107.121 19.9881C107.194 19.9152 107.251 19.8285 107.29 19.7331C107.329 19.6376 107.348 19.5353 107.347 19.4323V15.0922C107.348 14.9877 107.329 14.8838 107.291 14.7867C107.252 14.6896 107.194 14.6011 107.121 14.5264C107.05 14.4499 106.964 14.3892 106.868 14.3484C106.772 14.3075 106.668 14.2875 106.564 14.2894H96.6904C96.4778 14.2905 96.2743 14.3755 96.1239 14.5258C95.9736 14.6761 95.8887 14.8797 95.8876 15.0922V19.4323C95.8854 19.5367 95.9054 19.6403 95.9463 19.7364C95.9871 19.8325 96.0479 19.9189 96.1245 19.9898C96.1994 20.063 96.2881 20.1206 96.3855 20.1591C96.4829 20.1977 96.587 20.2164 96.6918 20.2142V20.2142Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.879 12.603H132.683V14.2755H126.133V27.4197H124.46V14.2755H117.879V12.603ZM141.656 27.4128V12.603H143.329V27.4128H141.656ZM165.557 12.603H167.697L161.506 20.0079L167.717 27.4128H165.557L160.045 20.8511H155.273V27.4128H153.589V12.603H155.276V19.1633H160.048L165.557 12.603Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_422_7349">
          <rect width="167.805" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRight;
