import { PropsWithChildren } from 'react';

import * as S from './Container.styles';

interface Props {
  noPadding?: boolean;
  className?: string;
}

const Container = ({ noPadding, className, children }: PropsWithChildren<Props>) => {
  return (
    <S.Wrapper className={className} noPadding={noPadding}>
      {children}
    </S.Wrapper>
  );
};

export default Container;
