import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import Icon from 'components/Icon';

export const DisclosureSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.15s linear;
  padding: 0 22px;
  font-family: 'LEMON MILK';
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => lighten(0.04, theme.colors.background.main)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0 16px;
    height: 60px;
  }
`;

export const ArrowIcon = styled(Icon)<{ open?: boolean }>`
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}

  transition: transform 0.15s linear;

  svg {
    stroke: ${({ theme }) => theme.colors.icon.light};
    transition: stroke 0.15s linear;
  }

  ${DisclosureSummary}:hover & {
    svg {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Disclosure = styled.div<{ contentHeight?: number }>`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background.main};

  .transition {
    transition: all 0.15s linear;
  }

  .opacity-0 {
    opacity: 0;
    height: 0;
  }

  .opacity-100 {
    opacity: 1;
    height: ${({ contentHeight }) => (contentHeight ? `${contentHeight}px` : 'auto')};
  }
`;

export const DisclosureContent = styled.div`
  padding: 22px;
`;
