import { ReactNode, useState } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';

import Icon from 'components/Icon';
import * as S from './AccordionItem.styles';

interface Props {
  title: string;
  content: ReactNode;
  open?: boolean;
  className?: string;
}
const AccordionItem = ({ className, open: initalOpen, title, content }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <S.Wrapper className={`accordion-item ${className}`} open={open}>
      <S.Heading onClick={() => setOpen(!open)}>
        {title}

        <Icon icon={open ? 'minus' : 'plus'} />
      </S.Heading>
      <motion.div
        className="anim"
        animate={{
          opacity: !open ? 0 : 1,
          height: !open ? 0 : 'auto',
          transition: {
            height: { duration: 0.4 },
            opacity: { duration: 0.2, delay: open ? 0.1 : 0 },
          },
        }}
      >
        <S.Content>{content}</S.Content>
      </motion.div>
    </S.Wrapper>
  );
};

export default AccordionItem;
