import styled from 'styled-components';
import { lighten } from 'polished';

import Icon from 'components/Icon';

export const Group = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ theme }) => theme.colors.background.main};
  transition: border-color 0.15s linear;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 40px;
  }
`;

export const Option = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.border.main};
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => lighten(0.03, theme.colors.background.main)};
  }
`;

export const CurrencyIcon = styled(Icon)<{ selected?: boolean }>`
  svg {
    transition: fill 0.15s linear;
    fill: ${({ theme, selected }) => (selected ? theme.colors.primary.blue : theme.colors.white)};
  }
`;
