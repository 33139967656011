import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 100px 0;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.light};
  font-family: 'Montserrat';
  user-select: none;
`;
