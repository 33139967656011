import { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import * as S from './Modal.styles';

export type ModalSize = '30prc' | '40prc' | '60prc' | '80prc';

interface Props extends ReactModal.Props {
  size?: ModalSize;
  onClose: () => void;
}

const Modal = ({ isOpen, onClose, children, size = '60prc', ...props }: Props) => {
  return (
    <>
      <S.Modal
        isOpen={isOpen}
        closeTimeoutMS={200}
        {...props}
        ariaHideApp={false}
        onRequestClose={onClose}
        size={size}
      >
        <S.CloseIcon icon="close" onClick={onClose} />

        {children}
      </S.Modal>
    </>
  );
};

const ModalTitle = ({ children }: PropsWithChildren<{}>) => {
  return <S.Title>{children}</S.Title>;
};

const ModalContent = ({ children }: PropsWithChildren<{}>) => {
  return <S.Content>{children}</S.Content>;
};

const ModalActions = ({ children }: PropsWithChildren<{}>) => {
  return <S.Actions>{children}</S.Actions>;
};

export default Modal;

export { ModalTitle, ModalContent, ModalActions };
