import { useReducer } from 'react';
import shallowEqual from 'shallowequal';

const reducer = <State extends Record<string, unknown>>(state: State, partialState: Partial<State>) => {
  const newState = { ...state, ...partialState };

  if (shallowEqual(state, newState)) return state;

  return newState;
};

type Reducer<S extends Record<string, unknown>> = (state: S, partialState: Partial<S>) => S;

export const useStateX = <State extends Record<string, unknown>>(initialState: State) => {
  return useReducer<Reducer<State>>(reducer, initialState);
};

export default useStateX;
