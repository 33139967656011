import { PropsWithChildren } from 'react';
import * as S from './Panel.styles';

interface Props {
  title?: string;
  className?: string;
}
const Panel = ({ title, children, className }: PropsWithChildren<Props>) => {
  return (
    <S.Bg className={className}>
      {title && <S.Title>{title}</S.Title>}

      {children}
    </S.Bg>
  );
};

export default Panel;
