import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import * as S from './CopyToClipboard.styles';

interface Props {
  str: string;
  maxChars?: number;
}

export const getMinimizedStr = ({ str, maxChars }: { maxChars?: number; str: string }) => {
  if (!maxChars || maxChars >= str.length) {
    return str;
  }

  const start = Math.floor(maxChars / 2);
  const end = Math.ceil(maxChars / 2);

  return str.slice(0, start) + '...' + str.slice(str.length - end, str.length);
};

const CopyToClipboard = ({ str, maxChars }: Props) => {
  const minimizedStr = getMinimizedStr({ str, maxChars });

  return (
    <S.Wrapper>
      {minimizedStr}
      <ReactCopyToClipboard text={str} onCopy={() => toast.success('Copied')}>
        <S.CopyIcon icon="copy" />
      </ReactCopyToClipboard>
    </S.Wrapper>
  );
};

export default CopyToClipboard;
