import Icon from 'components/Icon';
import Tabs from 'components/Tabs';

import { FilterState } from 'pages/Marketplace/Marketplace';

import * as S from './Filters.styles';

export type FilterTab = 'sale' | 'latest';
export type Sort = 'newest' | 'oldest';

export const TABS: { value: FilterTab; label: string }[] = [
  { value: 'sale', label: 'For Sale' },
  { value: 'latest', label: 'Latest sales' },
];

const SORT_OPTIONS: { value: Sort; label: string }[] = [
  { value: 'newest', label: 'Newest first' },
  { value: 'oldest', label: 'Oldest first' },
];

interface Props {
  values: FilterState;
  showOnlyTabs?: boolean;
  onChange: (field: keyof FilterState, value: FilterState[keyof FilterState]) => void;
}

const Filters = ({ values, showOnlyTabs, onChange }: Props) => {
  const { tab, currency, sort, showAdditionalFilters, searchStr } = values;

  return (
    <S.Wrapper>
      <Tabs tabs={TABS} value={tab} onTabChange={(value) => onChange('tab', value)} />
      {!showOnlyTabs && (
        <S.RightSide>
          <S.FilterButton
            id="filter-button"
            active={showAdditionalFilters}
            onClick={() => onChange('showAdditionalFilters', !showAdditionalFilters)}
          >
            <Icon icon="filter" />
          </S.FilterButton>

          <S.SortSelect<Sort>
            options={SORT_OPTIONS}
            value={sort}
            onChange={(value) => onChange('sort', value)}
          />

          <S.SearchInput
            startSlot={<S.SearchIcon icon="search" />}
            placeholder="Search by name..."
            value={searchStr}
            onChange={(e) => onChange('searchStr', e.target.value)}
          />

          <S.StyledSwitcher selected={currency} onChange={(value) => onChange('currency', value)} />
        </S.RightSide>
      )}
    </S.Wrapper>
  );
};

export default Filters;
