import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './Background.styles';

const Background = ({ children }: PropsWithChildren<{}>) => {
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  return (
    <div style={{ position: 'relative' }}>
      <>
        <S.Bg>
          <S.TopLight />
          {!isHomePage && <S.BottomLines />}
          <S.BottomLeftLight />
          <S.MiddleRightLight />
          <S.TopLines />
        </S.Bg>
        {isHomePage && (
          <S.BgWrapper>
            <S.BottomLines2 />
          </S.BgWrapper>
        )}
        {children}
      </>
    </div>
  );
};

export default Background;
