import { DefaultTheme } from 'styled-components';

const commonStyles = {
  breakpoints: {
    xs: 375,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1440,
    extra: 1600,
    largest: 1920,
  },
};

export const themeDark: DefaultTheme = {
  ...commonStyles,
  colors: {
    white: '#ffffff',
    black: '#000000',
    primary: {
      light: '#8C60EA',
      main: '#5D33B8',
      blue: '#00B4FF',
    },
    border: {
      main: '#1A142E',
    },
    background: {
      light: '#252443',
      main: '#0F0524',
      card: '#0B071C',
      gradient: 'linear-gradient(270deg, #5B3BA0 0%, #00B4FF 100%)',
      footer: '#080412',
      sidebar: '#110B27',
    },
    success: {
      main: '#00C064',
    },
    warning: {
      main: '#E9C748',
    },
    error: {
      main: '#e73232',
    },
    text: {
      light: '#9E95A9',
    },
    icon: {
      sidebar: '#D3D3D3',
      light: '#504B62',
    },
    table: {
      head: '#10082D',
    },
  },
};

export const themeLight: DefaultTheme = {
  ...themeDark,
};
