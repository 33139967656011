import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import CopyToClipboard from 'components/CopyToClipboard';
import Modal, { ModalActions, ModalContent, ModalTitle } from 'components/Modal';
import ChainInfo from 'components/ChainInfo';

import * as S from './AccoutModal.styles';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
}

const AccoutModal = ({ isOpen = false, onClose }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { address, isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { chain, chains } = useNetwork();

  useEffect(() => {
    isDisconnected && onClose();
  }, [isDisconnected, onClose]);

  if (!address) return null;

  const isSupported = !!chains.find(({ id }) => id === chain?.id);

  const handleDisconnect = () => {
    if (location.pathname.includes('/user')) {
      navigate('/');
    }
    disconnect();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="30prc">
      <ModalTitle>Your Account</ModalTitle>
      <ModalContent>
        <S.Block>
          <S.Title>Your address</S.Title>
          <S.Text>
            <CopyToClipboard str={address} maxChars={12} />
          </S.Text>
        </S.Block>
        {chain?.name && (
          <S.Block>
            <S.Title>Chain</S.Title>
            <S.Text error={!isSupported}>
              {chain.name} {!isSupported && '(not supported)'}
            </S.Text>
          </S.Block>
        )}
        <ChainInfo />
      </ModalContent>

      <ModalActions>
        <Button variant="outline" onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleDisconnect}>Disconnect</Button>
      </ModalActions>
    </Modal>
  );
};

export default AccoutModal;
