import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Button from 'components/Button';
import Hamburger from 'components/Hamburger';
import * as HeaderS from '../../Header.styles';

export const Backdrop = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: ${({ isOpen }) => (isOpen ? '340px' : 0)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  padding: 20px;
  padding-top: 70px;
  padding-left: 50px;
  background-color: ${({ theme }) => theme.colors.background.main};
  transition: width 0.2s linear, opacity 0.2s linear;
`;

export const Wrapper = styled(HeaderS.Wrapper)`
  z-index: 1;
  position: relative;
`;

export const Navigation = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const Link = styled(NavLink)`
  list-style: none;
  text-decoration: none;
`;

export const NavigationItem = styled.li<{ isActive?: boolean }>`
  padding: 0;
  height: 40px;
  font-size: 20px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  transition: color 0.15s linear, border 0.15s linear;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary.main};
      border-radius: 2px;
    `}
`;

export const LogoImg = styled.img`
  z-index: -1;
`;

export const ActionSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ConnectButton = styled(Button)`
  z-index: 0;
`;

export const HamburgerBtn = styled(Hamburger)`
  position: relative;
  z-index: 3;
`;
