import { useState } from 'react';
import { useLockBodyScroll } from 'react-use';
import { useAccount } from 'wagmi';

import logoSrc from 'assets/images/crisiumLogo2.svg';
import useClickOutside from 'hooks/useClickOutside';
import { getMinimizedStr } from 'components/CopyToClipboard/CopyToClipboard';

import * as S from './MobileHeader.styles';
import { ROUTES_FULL } from 'components/Header/Header.constants';

interface Props {
  onConnectClick: () => void;
}

const MobileHeader = ({ onConnectClick }: Props) => {
  const { address } = useAccount();
  const [isOpen, setOpen] = useState(false);

  const ref = useClickOutside<HTMLDivElement>(() => setOpen(false));

  useLockBodyScroll(isOpen);

  return (
    <S.Wrapper ref={ref}>
      <S.LogoImg src={logoSrc} alt="Crisium Logo" width={90} />
      <S.ActionSide>
        <S.ConnectButton onClick={onConnectClick}>
          {address ? getMinimizedStr({ str: address, maxChars: 8 }) : 'Connect'}
        </S.ConnectButton>

        <S.HamburgerBtn isMenuOpen={isOpen} onClick={() => setOpen(!isOpen)} />
      </S.ActionSide>
      <S.Backdrop isOpen={isOpen}>
        {isOpen && (
          <S.Navigation>
            {ROUTES_FULL.map(({ to, label }) => (
              <S.Link to={to} key={to} onClick={() => setOpen(false)}>
                {({ isActive }) => <S.NavigationItem isActive={isActive}>{label}</S.NavigationItem>}
              </S.Link>
            ))}

            <S.Link to="/user" onClick={() => setOpen(false)}>
              {({ isActive }) => <S.NavigationItem isActive={isActive}>my profile</S.NavigationItem>}
            </S.Link>
          </S.Navigation>
        )}
      </S.Backdrop>
    </S.Wrapper>
  );
};

export default MobileHeader;
