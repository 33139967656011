import CloseIcon from './CloseIcon';
import ChevronDownIcon from './ChevronDownIcon';
import SearchIcon from './SearchIcon';
import CrisiumIcon from './CrisiumIcon';
import EthIcon from './EthIcon';
import UsdIcon from './UsdIcon';
import FilterIcon from './FilterIcon';
import CopyIcon from './CopyIcon';
import InfoIcon from './InfoIcon';
import LinkIcon from './LinkIcon';
import ArrowRight from './ArrowRight';
import UserCircleFilled from './UserCircleFilled';
import DiscordIcon from './DiscordIcon';
import FacebookIcon from './FacebookIcon';
import InstagramIcon from './InstagramIcon';
import TwitterIcon from './TwitterIcon';
import YoutubeIcon from './YoutubeIcon';
import TelegramIcon from './TelegramIcon';
import CertikIcon from './CertikIcon';
import HomeIcon from './HomeIcon';
import UserIcon from './UserIcon';
import RocketIcon from './RocketIcon';
import ShoppingBagIcon from './ShoppingBagIcon';
import StackIcon from './StackIcon';
import FolderIcon from './FolderIcon';
import AcademicIcon from './AcademicIcon';
import RectGroupIcon from './RectGroupIcon';
import PlusIcon from './PlusIcon';
import MinusIcon from './MinusIcon';

const icons = {
  close: CloseIcon,
  certik: CertikIcon,
  'chevron-down': ChevronDownIcon,
  discord: DiscordIcon,
  'arrow-right': ArrowRight,
  search: SearchIcon,
  crisium: CrisiumIcon,
  eth: EthIcon,
  usd: UsdIcon,
  facebook: FacebookIcon,
  filter: FilterIcon,
  copy: CopyIcon,
  info: InfoIcon,
  instagram: InstagramIcon,
  link: LinkIcon,
  'user-circle': UserCircleFilled,
  twitter: TwitterIcon,
  telegram: TelegramIcon,
  youtube: YoutubeIcon,
  home: HomeIcon,
  user: UserIcon,
  rocket: RocketIcon,
  'shopping-bag': ShoppingBagIcon,
  stack: StackIcon,
  folder: FolderIcon,
  academy: AcademicIcon,
  'rect-group': RectGroupIcon,
  plus: PlusIcon,
  minus: MinusIcon,
};

export default icons;
