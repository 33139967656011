import styled, { css } from 'styled-components';

import Button from 'components/Button';
import Panel from 'components/Panel';
import Breadcrumbs from 'components/Breadcrumbs';

export const Wrapper = styled.div``;

export const BreadcrumbsWrapper = styled(Breadcrumbs)`
  margin-bottom: 30px;
`;

export const Text = styled.span<{ white?: boolean; hoverable?: boolean }>`
  color: ${({ theme, white }) => (white ? theme.colors.white : theme.colors.text.light)};
  transition: color 0.15s linear;

  ${({ hoverable }) =>
    hoverable &&
    css`
      cursor: pointer;
      &:hover {
        color: ${({ theme }) => theme.colors.primary.blue};
      }
    `}
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;
  height: min-content;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
  }
`;

export const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 400px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 100%;
  }
`;

export const Info = styled.div`
  font-family: 'Montserrat';
`;

export const UserAvatar = styled.div<{ src: string }>`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  margin-right: 6px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 8px;
  }
`;

export const Description = styled.div`
  margin-top: 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.light};
  line-height: 1.4;
  max-width: 64%;
  margin-bottom: 36px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 100%;
    margin-bottom: 14px;
    line-height: 1.2;
  }
`;

export const PriceTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 4px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 14px;
    margin-bottom: 0;
  }
`;

export const Price = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.blue};
  margin-bottom: 36px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 14px;
    margin-bottom: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    & > * {
      flex-grow: 1;
    }
  }
`;

export const ActionButton = styled(Button)`
  min-width: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-grow: 1;
    min-width: unset;
  }
`;

export const DetailsContent = styled.div`
  padding: 18px 24px;
  font-family: 'Montserrat';
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const DetailsKey = styled.div`
  font-size: 16px;
  font-weight: 400;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 12px;
  }
`;

export const DetailValue = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
`;

export const HelperText = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  padding: 20px 0;
`;

export const OffersPanel = styled(Panel)`
  margin-bottom: 24px;
`;

export const PriceWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
  }
`;
