import CurrencySwitcher from 'components/CurrencySwitcher';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Select from 'components/Select';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
    align-items: flex-start;

    gap: 10px;
    margin-bottom: 24px;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100%;
    gap: 6px;
  }
`;

export const FilterButton = styled.div<{ active?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.background.main};
  cursor: pointer;
  transition: border-color 0.15s linear;

  & > * {
    pointer-events: none;
    user-select: none;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
    transition: fill 0.15s linear;
  }

  &:hover {
    background-color: ${({ theme }) => lighten(0.03, theme.colors.background.main)};

    border-color: ${({ theme }) => theme.colors.primary.main};
  }

  ${({ active }) =>
    active &&
    css`
      svg {
        fill: ${({ theme }) => theme.colors.primary.blue};
      }
    `}
`;

export const StyledSwitcher = styled(CurrencySwitcher)`
  width: 240px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 200px;
    flex-grow: 1;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const SortSelect = styled(Select)`
  min-width: 200px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-grow: 1;
  }
` as typeof Select;

export const SearchInput = styled(Input)`
  min-width: 250px;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-grow: 1;
    width: 100%;
    min-width: auto;
  }
`;

export const SearchIcon = styled(Icon)`
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
