import { useEffect, useMemo } from 'react';

import Disclosure from 'components/Disclosure';
import Input from 'components/Input';
import useStateX from 'hooks/useStateX';
import * as S from './AdditionalFilters.styles';
import Select from 'components/Select';

export interface FilterValues {
  min: string;
  max: string;
  category: string;
}

interface Props {
  values?: FilterValues;
  onApply: (values: FilterValues) => void;
}

const AdditionalFilters = ({ values, onApply }: Props) => {
  const [state, setState] = useStateX({
    min: values?.min || '',
    max: values?.max || '',
    category: values?.category || 'all',
  });

  useEffect(() => {
    setState({ min: values?.min || '', max: values?.max || '', category: values?.category || 'all' });
  }, [values, setState]);

  const { min, max, category } = state;

  const isDisabled = useMemo(() => {
    return Object.entries(state).every(([key, value]) => values?.[key as keyof FilterValues] === value);
  }, [state, values]);

  const handleApply = () => onApply(state);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'min' | 'max') => {
    const value = e.target.value;
    if (Number.isNaN(value) && value !== '') return;

    setState({ [type]: value });
  };

  return (
    <>
      <div style={{ marginBottom: 14 }}>
        <Disclosure
          SummarySlot="Price"
          ContentSlot={
            <S.MinMaxPriceWrapper>
              <Input
                type="number"
                placeholder="min"
                value={min}
                onChange={(e) => handleInputChange(e, 'min')}
              />
              <Input
                type="number"
                placeholder="max"
                value={max}
                onChange={(e) => handleInputChange(e, 'max')}
              />
              <S.ToWrapper>to</S.ToWrapper>
            </S.MinMaxPriceWrapper>
          }
          contentHeight={100}
        />
      </div>
      <Disclosure
        SummarySlot="Category"
        defaultOpen
        ContentSlot={
          <Select
            value={category}
            options={[
              { value: 'all', label: 'All' },
              { value: 'core', label: 'Core' },
              { value: 'attachment', label: 'Attachment' },
            ]}
            onChange={(value) => {
              setState({ category: value });
            }}
          />
        }
        contentHeight={100}
      />
      <S.ApplyButton onClick={handleApply} disabled={isDisabled}>
        Apply
      </S.ApplyButton>
    </>
  );
};

export default AdditionalFilters;
