import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import hexToRgba from 'utils/hexToRgba';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.sidebar};
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 2;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 0;
  box-sizing: border-box;

  .tooltip {
    background-color: ${({ theme }) => theme.colors.background.main};
    font-family: 'Montserrat';
    padding: 4px 10px;
    border: 1px solid ${({ theme }) => theme.colors.border.main};
    border-radius: 10px;
    font-size: 14px;
  }

  .tooltipArrow {
    border-left: 1px solid ${({ theme }) => theme.colors.border.main};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 60px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const LogoSquare = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 10px;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.primary.main};
  margin-bottom: 50px;
`;

export const SidebarItem = styled(NavLink)<{ $isActive?: boolean }>`
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.15s linear;

  svg {
    transition: fill 0.15s linear;
    user-select: none;
    fill: ${({ theme }) => theme.colors.icon.sidebar};
  }

  &:hover {
    background-color: ${({ theme }) => hexToRgba(theme.colors.primary.main, 0.2)};
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => hexToRgba(theme.colors.primary.main, 0.4)};
      svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    `}
`;
