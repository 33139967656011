import { lighten } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div<{ open?: boolean }>`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ theme }) => theme.colors.background.light};
  font-family: 'Montserrat';

  .anim {
    overflow: hidden;
    height: 0;
  }
`;

export const Heading = styled.div`
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => lighten(0.02, theme.colors.background.main)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  padding: 20px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.4;
`;
