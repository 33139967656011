import * as S from './Nft.styles';
import * as HomeS from '../../Home.styles';
import walletSrc from 'assets/images/home/wallet.png';
import collectionSrc from 'assets/images/home/collection.png';
import personSrc from 'assets/images/home/person.png';
import shopSrc from 'assets/images/home/shop.png';

const CARDS = [
  {
    src: walletSrc,
    title: 'NFT Airdrops',
    description: 'Get airdrops on future NFTs or whitelist invitations for being a Ticket holder.',
  },
  {
    src: collectionSrc,
    title: 'Incredible Experiences',
    description: 'Invites to the launch event, to Houston HQ, and other once-in-a-lifetime opportunities.',
  },
  {
    src: personSrc,
    title: 'Exclusive Merch',
    description: 'Get the chance to own exclusive merch from the mission that no one can buy.',
  },
  {
    src: shopSrc,
    title: 'Other Mystery Prizes',
    description: 'Ticket holders have surprises in store. Don’t miss out on other rare item drops.',
  },
];

const Nft = () => {
  return (
    <S.Wrapper>
      <HomeS.Suptitle>The Rewards</HomeS.Suptitle>
      <S.SectionTitle>ONCE-IN-A-LIFETIME PRIZES</S.SectionTitle>

      <S.Text>
        Ticket holders can receive future NFT airdrops, get to lottery for invitations to once-in-a-lifetime
        events like the rocket launch and a Houston HQ visit, and have the chance to receive exclusive merch
        and other rare items around the launch.
        <br />
        Prizes will be announced soon. Join the Discord to be the first to hear.
      </S.Text>

      <S.Cards>
        {CARDS.map(({ src, title, description }) => (
          <S.Card key={title}>
            <S.CardContrainer>
              <S.CardImage src={src} />
            </S.CardContrainer>
            <S.CardTitle>{title}</S.CardTitle>
            <S.CardDescription>{description}</S.CardDescription>
          </S.Card>
        ))}
      </S.Cards>

      <S.Video width="auto" autoPlay loop muted>
        <source src={'./MysteryTicket.mp4'} type="video/mp4" />
      </S.Video>
    </S.Wrapper>
  );
};

export default Nft;
