import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-size: 14px;
`;

export const Breadcrumb = styled.div<{ clickable?: boolean; isLast?: boolean }>`
  color: ${({ theme, isLast }) => (isLast ? theme.colors.text.light : theme.colors.white)};
  transition: color 0.15s linear;
  margin-right: 8px;

  ${({ clickable }) =>
    clickable &&
    css`
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        color: ${({ theme }) => theme.colors.primary.blue};
      }
    `}
`;
