import { ButtonHTMLAttributes } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';

import * as S from './Button.styles';

export type ButtonVariant = 'filled' | 'outline' | 'text' | 'gradient';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  loading?: boolean;
}

const Button = ({ children, variant = 'filled', loading, disabled, ...props }: Props) => {
  const theme = useTheme();
  return (
    <S.Button variant={variant} disabled={loading || disabled} {...props}>
      {loading ? (
        <ClipLoader
          color={theme.colors.white}
          loading={loading}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        children
      )}
    </S.Button>
  );
};

export default Button;
