import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';
import CopyToClipboard from 'components/CopyToClipboard';
import * as S from './Footer.styles';
import logoSrc from 'assets/images/crisiumLogo2.svg';
import imLogoSrc from 'assets/images/imLogo.png';

const Footer = () => {
  return (
    <S.Wrapper>
      <S.Content>
        {/* <S.LeftSection> */}
        {/* <S.LeftTopSection> */}
        <S.Section>
          <S.CrisiumLogo src={imLogoSrc} />
          <S.CrisiumLogo src={logoSrc} />

          {/* <S.SectionTitle>Contacts</S.SectionTitle>

          <S.SectionContent>
            <S.Text>
              3700 Bay Area Blvd. Houston, <br /> TX 77058 Sixth Floor - Suite 600
            </S.Text>
            <S.Text>P 281.520.3703</S.Text>
          </S.SectionContent> */}
          {/* <Button variant="outline">Contact</Button> */}
        </S.Section>
        <S.Section>
          <S.SectionTitle>Get updates on the drop</S.SectionTitle>

          <S.SectionContent>
            <S.Text>
              Enter your email to be notified when the countdown begins to the Tickets drop. Don’t miss out.
            </S.Text>
          </S.SectionContent>
          <S.EmailForm>
            <Input placeholder="Your email..." />
            <Button
              onClick={() => {
                window.location.href =
                  'mailto:info@crisium.io?subject=Drop%20Notification&body=I%20want%20to%20be%20notified%20about%20the%20Tickets%20drop';
              }}
            >
              Submit
            </Button>
          </S.EmailForm>
        </S.Section>
        {/* </S.LeftTopSection> */}
        {/* </S.LeftSection> */}

        <S.RightSection>
          <S.SocialIcons>
            <S.Icon
              icon="twitter"
              onClick={() => window.open('https://twitter.com/CrisiumSpace', '_blank')}
            />
            <S.Icon icon="telegram" onClick={() => window.open('https://t.me/+DtRh3Cq76yJkZGIx', '_blank')} />
            <S.Icon icon="discord" onClick={() => window.open('https://discord.gg/ZdRHKN4m', '_blank')} />
          </S.SocialIcons>

          <S.CertikSection>
            <Icon icon="certik" />
            <S.Text>
              Contract Address:
              <CopyToClipboard str={'0x0000000000000000000000000000000000000000'} maxChars={12} />
            </S.Text>
          </S.CertikSection>
        </S.RightSection>
      </S.Content>
      <S.Links>
        <S.Link
          onClick={() => {
            window.open(`${window.location.origin}/terms`, '_blank');
            // navigate('/terms');
          }}
        >
          Terms & Conditions
        </S.Link>
        <S.Link>Privacy Policy</S.Link>
        <S.Link>Purchase Order T&C</S.Link>
      </S.Links>
    </S.Wrapper>
  );
};

export default Footer;
