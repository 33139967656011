export const ROUTES = [
  { to: '/marketplace', label: 'Marketplace' },
  // { to: '/missions', label: 'Missions' },
  // { to: '/staking', label: 'Staking' },
];

export const ROUTES_FULL = [
  { to: '/', label: 'Home' },
  { to: '/marketplace', label: 'Marketplace' },
  // { to: '/missions', label: 'Missions' },
  // { to: '/staking', label: 'Staking' },
  { to: '/about', label: 'About' },
  { to: '/faq', label: 'Faq' },
  { to: '/news', label: 'News' },
];
