import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0;
  box-sizing: border-box;

  background: ${({ theme }) => theme.colors.background.card};
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 10px;
  box-shadow: 0px 10px 18px 0px #09041824;
  border: 2px solid ${({ theme }) => theme.colors.border.main};
  transition: all 0.15s linear;
  cursor: pointer;

  &:hover {
    border: solid 2px transparent;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -2px;
      border-radius: inherit;
      background: linear-gradient(180deg, #2c83d3 0%, #542ea6 100%);
    }
  }
`;

export const Image = styled.div<{ src?: string; crop?: boolean; bg?: string }>`
  width: 100%;
  height: 260px;
  position: relative;
  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `};

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;

  ${Wrapper}:hover & {
    border-radius: 8px 8px 0 0;
  }

  ${({ crop }) =>
    crop &&
    css`
      background-size: contain;
      margin: 14px 10px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 200px;
  }
`;

export const HelperText = styled.span`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 14px;
  font-family: 'Montserrat';
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Title = styled.h6`
  font-size: 22px;
  margin: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'LEMON MILK';
  letter-spacing: 0.1em;
  line-height: 30px;
  margin-bottom: 6px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
  }
`;

export const Description = styled.div`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.text.light};
  margin-bottom: 18px;
`;
