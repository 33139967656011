import { InputHTMLAttributes, ReactNode } from 'react';
import * as S from './Input.styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  startSlot?: ReactNode;
  className?: string;
}

const Input = ({ startSlot: StartSlot, className, ...props }: Props) => {
  return (
    <S.Wrapper hasStartSlot={!!StartSlot} className={className}>
      {StartSlot && <S.StartSlot>{StartSlot}</S.StartSlot>}
      <S.Input {...props}></S.Input>
    </S.Wrapper>
  );
};

export default Input;
