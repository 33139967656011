import styled from 'styled-components';

export const Wrapper = styled.div<{ hasStartSlot?: boolean }>`
  height: 48px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background.main};
  padding: ${({ hasStartSlot }) => (hasStartSlot ? '0 20px 0 10px' : '0 20px')};
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  transition: border-color 0.15s linear;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input`
  outline: none;
  background-color: transparent;
  font-size: 14px;
  height: 100%;
  width: 100%;
  font-weight: 600;

  color: ${({ theme }) => theme.colors.white};
  font-family: 'Montserrat';
  border: none;
  &:focus {
    outline: none;
  }
`;

export const StartSlot = styled.div`
  margin-right: 10px;
  user-select: none;
`;
