import styled from 'styled-components';

export const Label = styled.label`
  font-size: 12px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.text.light};
  font-family: 'Montserrat';
  margin-bottom: 4px;
  display: inline-block;
`;

export default Label;
