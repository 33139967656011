import type { CardProps } from 'components/Card';
import { Currency } from 'components/CurrencySwitcher/CurrencySwitcher.constants';
import mockAvatar from 'assets/images/mockAvatar.png';
import * as S from './MarketplaceCard.styles';

interface Props extends CardProps {
  id: string;
  owner: string;
  avatar?: string;
  price: string;
  currency: Currency;
  buyLoading?: boolean;
  crop?: boolean;
  onBuy?: (id: string) => void;
  onClick: () => void;
}

const MarketplaceCard = ({ avatar = mockAvatar, owner, price, onBuy, buyLoading, ...props }: Props) => {
  const renderInfo = () => {
    return (
      <S.ItemInfoWrapper>
        <S.ItemInfo>
          <S.User>
            <S.Avatar src={avatar} />
            <S.Username>{owner}</S.Username>
          </S.User>
          <S.Price>{price}</S.Price>
        </S.ItemInfo>
        {onBuy && (
          <S.BuyButton
            loading={buyLoading}
            variant="gradient"
            onClick={(e) => {
              e.stopPropagation();
              onBuy(props.id);
            }}
          >
            BUY
          </S.BuyButton>
        )}
      </S.ItemInfoWrapper>
    );
  };
  return <S.MarketCard {...props} endSlot={renderInfo()} contentClassName="content" descClassName="desc" />;
};

export default MarketplaceCard;
