import { useState } from 'react';

import Button from 'components/Button';
import { Currency } from 'components/CurrencySwitcher/CurrencySwitcher.constants';
import Label from 'components/Label';
import Modal, { ModalActions, ModalContent, ModalTitle } from 'components/Modal';
import Select from 'components/Select';

import * as S from './OfferModal.styles';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: (values: { price: string; duration: string; currency: Currency }) => void;
}

const OPTIONS = [
  { label: '1 day', value: '1-day' },
  { label: '3 days', value: '3-day' },
  { label: '7 days', value: '7-day' },
  { label: 'month', value: '1-month' },
];

const OfferModal = ({ isOpen = false, onClose, onSubmit }: Props) => {
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState<Currency>(Currency.CRISIUM);
  const [duration, setDuration] = useState(OPTIONS[0].value);

  const handleSubmit = () => {
    onSubmit({ currency, price, duration });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="60prc">
      <ModalTitle>Make an offer</ModalTitle>
      <ModalContent>
        <S.OffetAmount>
          <Label>Offer Amount</Label>
          <S.Offer>
            <S.OfferInput
              placeholder="132.00"
              type="number"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
            />
            <S.Currency selected={currency} onChange={setCurrency} />
          </S.Offer>
        </S.OffetAmount>
        <Label>Offer Expiration</Label>
        <Select options={OPTIONS} value={duration} onChange={setDuration} />
      </ModalContent>
      <ModalActions>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!price}>
          make an offer
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default OfferModal;
