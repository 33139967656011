import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';
import { useNetwork, useSigner, chain } from 'wagmi';
import backed20Abi from './BackedERC20.json';
import bridgeAbi from './FiatBridgeV2.json';

export const getContract = ({
  abi,
  address,
  chainId = chain.mainnet.id,
  signer,
}: {
  abi: any;
  address: string;
  chainId?: number;
  signer?: Signer;
}) => {
  const signerOrProvider = signer;
  return new Contract(address, abi, signerOrProvider);
};

/**@deprecated */
export const getBacked20Contract = (address: string, signer?: Signer) => {
  return address ? getContract({ abi: backed20Abi, address, signer }) : undefined;
};

/**@deprecated */
export const getBridgeContract = (address: string, signer?: Signer) => {
  return address ? getContract({ abi: bridgeAbi, address, signer }) : undefined;
};

/**@deprecated */
export const useBacked20Contract = () => {
  const { data: signer } = useSigner();
  const { chain } = useNetwork();

  const bridgeContract = useMemo(
    () => (signer && getBacked20Contract('0x1DD9b404Be7b2afEdCFb38AfD2dbFc01423c6060', signer)) ?? undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chain, signer]
  );

  return bridgeContract;
};

/**@deprecated */
export const useBridgeContract = () => {
  const { data: signer } = useSigner();
  const { chain } = useNetwork();

  const bridgeContract = useMemo(
    () => (signer && getBridgeContract('0x5f94d9356D7135B5b76dDf647F2B2CCbE63318D8', signer)) ?? undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chain, signer]
  );

  return bridgeContract;
};
