import { useEffect, type PropsWithChildren, useState } from 'react';
import * as S from './SneakBackdrop.styles';
import { InfoModal } from './components/InfoModal';
import { useNavigate } from 'react-router-dom';

interface Props {
  messageTitle: string;
  messageDescription: string;
}

export const SneakBackdrop = ({ children, messageTitle, messageDescription }: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const [isBgOpaque, setIsBgOpaque] = useState(false);
  const [showChildren, setShowChildren] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const opaqueTimeout = setTimeout(() => {
      setIsBgOpaque(true);
      setIsModalOpen(true);
      clearTimeout(opaqueTimeout);
    }, 1500);
    const timeout = setTimeout(() => {
      setShowChildren(false);
      clearTimeout(timeout);
    }, 1600);

    return () => {
      clearTimeout(opaqueTimeout);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <S.MainWrapper>
      {showChildren && children}
      <S.Wrapper opaque={isBgOpaque}></S.Wrapper>

      <InfoModal
        title={messageTitle}
        description={messageDescription}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          navigate('/');
        }}
      />
    </S.MainWrapper>
  );
};
