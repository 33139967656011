import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalStorage, useLockBodyScroll } from 'react-use';
import { useTheme } from 'styled-components';
import { useEffect, useState } from 'react';

import { Currency } from 'components/CurrencySwitcher/CurrencySwitcher.constants';
import useStateX from 'hooks/useStateX';
import Filters from './components/Filters';
import MarketplaceCard from './components/MarketplaceCard';
import AdditionalFilters from './components/AdditionalFilters';
import LatestSales from './components/LatestSales';
import { FilterTab, Sort } from './components/Filters/Filters';
import { MOCK_ITEMS, MOCK_SALES } from './Marketplace.constants';
import * as S from './Marketplace.styles';
import { useBridgeContract } from 'hooks/useBacked20Contract';
import { constants, ethers } from 'ethers';
import { MarketItem } from 'pages/MarketplaceItem/MarketplaceItem';
import { SneakBackdrop } from 'components/SneakBackdrop';

export type FilterState = {
  tab: FilterTab;
  currency: Currency;
  sort: Sort;
  searchStr: string;
  showAdditionalFilters: boolean;
  min: string;
  max: string;
  category: string;
};

const initAdditionalFilters = {
  min: '',
  max: '',
};

const init: FilterState = {
  tab: 'sale',
  sort: 'newest',
  currency: Currency.CRISIUM,
  showAdditionalFilters: false,
  searchStr: '',
  category: 'all',
  ...initAdditionalFilters,
};

const Marketplace = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [state, setState] = useStateX(init);

  // TODO: update logic after demo
  const [buyingObj, setBuyingObj] = useState<{ [key: string]: boolean }>({});

  const [items = [], setItems] = useLocalStorage<MarketItem[]>('marketplace-items');
  const [purchased, setPurchased] = useLocalStorage<MarketItem[]>('purchased-items');
  // const [fromMarketItem, setFromMarketItem] = useLocalStorage<MarketItem[]>('from-market-items');

  // const contract = useBacked20Contract();
  const contract = useBridgeContract();

  console.log('contract', contract);

  const { currency, showAdditionalFilters, tab, sort, searchStr, ...additionalFilters } = state;

  const isBelowLg = window.innerWidth <= theme.breakpoints.lg;
  useLockBodyScroll(showAdditionalFilters && isBelowLg);

  // const ref = useRef<HTMLDivElement>(null);
  // useClickAway(
  //   ref,
  //   (e) => {
  //     if ((e.target as HTMLDivElement)?.id === 'filter-button') {
  //       return;
  //     }
  //     setState({ showAdditionalFilters: false });
  //   },
  //   ['click']
  // );

  const resetAdditionalFilters = () => {
    setState(initAdditionalFilters);
  };

  useEffect(() => {
    !items?.length && setItems(MOCK_ITEMS);
    !items?.length && setPurchased([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setItems, setPurchased]);

  return (
    <SneakBackdrop
      messageTitle="The digital marketplace for major Space missions"
      messageDescription="Coming Soon"
    >
      <S.Title>Marketplace</S.Title>
      <Filters
        values={state}
        onChange={(field, value) => setState({ [field]: value })}
        showOnlyTabs={tab === 'latest'}
      />
      <S.Wrapper>
        {tab === 'sale' && (
          <S.FilterWrapper isFilterOpen={showAdditionalFilters}>
            {isBelowLg && (
              <S.FilterControls>
                <S.CloseIcon icon="chevron-down" onClick={() => setState({ showAdditionalFilters: false })} />
                <div>Filters</div>
                <S.ClearButton onClick={resetAdditionalFilters}>clear</S.ClearButton>
              </S.FilterControls>
            )}
            <AdditionalFilters
              values={additionalFilters}
              onApply={(values) => {
                setState(values);
                // setState({ showAdditionalFilters: false });
              }}
            />
          </S.FilterWrapper>
        )}
        <S.Content>
          {tab === 'sale' && (
            <>
              <S.CardWrapper>
                {MOCK_ITEMS.filter(
                  ({ category }) => state.category === 'all' || state.category === category
                ).map((item) => (
                  <MarketplaceCard
                    key={item.id}
                    {...item}
                    currency={currency}
                    buyLoading={buyingObj[item.id]}
                    onBuy={async () => {
                      try {
                        setBuyingObj({ ...buyingObj, [item.id]: true });
                        console.log('contract?.approve', contract, constants.MaxUint256);
                        const res = await contract?.bridgeMint(
                          '0x2B8ad6DaDaD60C56005A057A21de0A49fb8D1c94',
                          ethers.BigNumber.from('100').mul(ethers.BigNumber.from(10).pow(18)).toHexString(),
                          `for-test-purposes-${Math.random()}`
                        );

                        console.log('res', res);

                        toast.success('Transaction is pending');

                        setTimeout(() => {
                          setItems(items?.filter(({ id }) => id !== item.id));
                          setPurchased([...(purchased || []), item]);
                          setBuyingObj({ ...buyingObj, [item.id]: false });
                          toast.success('You successfully bought an item');
                        }, 6000);
                      } catch (e: any) {
                        console.log('error', e, e?.message);
                        setBuyingObj({ ...buyingObj, [item.id]: false });
                        toast.error(e?.message || 'Something went wrong');
                      }
                    }}
                    onClick={() => navigate(`/marketplace/${item.id}`)}
                  />
                ))}
              </S.CardWrapper>
            </>
          )}

          {tab === 'latest' && <LatestSales latestSales={MOCK_SALES} />}
        </S.Content>
      </S.Wrapper>
    </SneakBackdrop>
  );
};

export default Marketplace;
