import { TH, Table } from 'components/Table';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const SectionTitle = styled.div`
  text-align: left;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 40px;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 26px;
  }
`;

export const Details = styled.div`
  font-size: 12px;
  text-align: left;
  font-family: 'Montserrat';
  margin: 16px 0;
`;

export const StyledTable = styled(Table)`
  overflow-x: scroll;
  box-sizing: border-box;
  display: block;
  max-width: 1100px;
`;

export const Ticket = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  transform: rotate(16deg);
  width: 68px;
  position: absolute;
  top: 0;
  left: 70px;
`;

export const StyledTH = styled(TH)`
  height: 100px;
  position: relative;
  min-width: 160px;
  width: 100%;
`;
