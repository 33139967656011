import * as S from './Sidebar.styles';
import logoSmall from 'assets/images/crisiumLogoSmall2.svg';
import Icon from 'components/Icon';
import icons from 'components/Icon/icons';
import { useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const SIDEBAR_ROUTES = [
  { to: '/', label: 'Home', icon: 'home' as keyof typeof icons },
  { to: '/user', label: 'User', icon: 'user' as keyof typeof icons },
  // { to: '/missions', label: 'Missions', icon: 'rocket' as keyof typeof icons },
  { to: '/marketplace', label: 'Marketplace', icon: 'shopping-bag' as keyof typeof icons },
  // { to: '/staking', label: 'Staking', icon: 'stack' as keyof typeof icons },
  { to: '/about', label: 'About', icon: 'rocket' as keyof typeof icons },
  { to: '/faq', label: 'Faq', icon: 'academy' as keyof typeof icons },
  // { to: '/news', label: 'News', icon: 'rect-group' as keyof typeof icons },
];

const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <S.Wrapper>
      <S.LogoSquare>
        <img src={logoSmall} alt="crisium" width={20} />
      </S.LogoSquare>

      {SIDEBAR_ROUTES.map(({ to, icon, label }) => {
        const sectionName = pathname.split('/')[1];
        const currentSectionName = to.split('/')[1];
        return (
          <div key={to} id={to} data-tooltip-content={label} style={{ marginBottom: 10 }}>
            <S.SidebarItem key={to} $isActive={sectionName === currentSectionName} to={to}>
              <Icon icon={icon} />
            </S.SidebarItem>

            <ReactTooltip anchorId={to} place="right" className="tooltip" classNameArrow="tooltipArrow" />
          </div>
        );
      })}
    </S.Wrapper>
  );
};

export default Sidebar;
