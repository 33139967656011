import { IconProps } from '../types';

const UsdIcon = ({ width = 12, height = 22, ...props }: IconProps) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
      <path d="M12 14.5245C12 11.9864 10.3244 10.3442 7.79599 9.21377C6.05017 8.43529 4.90635 8.17935 4.90635 7.39021C4.90635 6.80368 5.53846 6.3238 6.4214 6.3238C7.19398 6.3238 8.43813 6.71837 8.91973 7.62482L11.398 5.08677C10.9064 3.76442 9.63211 2.73 7.92642 2.32477V0H4.57525V2.39942C2.33779 3.04993 0.64214 4.88415 0.64214 7.25157C0.64214 9.84295 2.39799 11.2719 4.44482 12.2104C6.0301 12.9569 7.68562 13.7353 7.68562 14.6205C7.68562 15.3243 6.79264 15.6762 5.8796 15.6762C4.52508 15.6762 3.2709 15.0364 2.59866 13.9806L0 16.6893C0.672241 18.2676 2.45819 19.398 4.57525 19.7285V22H7.92642V19.5579C10.2441 18.8861 12 17.0839 12 14.5245Z" />
    </svg>
  );
};

export default UsdIcon;
