import { RadioGroup } from '@headlessui/react';

import { Currency, CURRENCIES } from './CurrencySwitcher.constants';
import * as S from './CurrencySwitcher.styles';

interface Props {
  className?: string;
  selected?: Currency;
  onChange: (currency: Currency) => void;
}

const CurrencySwitcher = ({ className, selected = Currency.CRISIUM, onChange }: Props) => {
  return (
    <RadioGroup className={className} value={selected} onChange={onChange} as={S.Group}>
      {CURRENCIES.map(({ value, icon }) => (
        <RadioGroup.Option key={value} value={value} as={S.Option}>
          {({ checked }) => <S.CurrencyIcon icon={icon} selected={checked} />}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default CurrencySwitcher;
