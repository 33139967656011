import { Fragment } from 'react';
import * as S from './Breadcrumbs.styles';

interface Props {
  className?: string;
  items: {
    label: string;
    onClick?: () => void;
  }[];
}

const Breadcrumbs = ({ items, className }: Props) => {
  return (
    <S.Wrapper className={className}>
      {items.map(({ label, onClick }, idx) => {
        const isLast = idx === items.length - 1;
        return (
          <Fragment key={label}>
            <S.Breadcrumb clickable={!!onClick} isLast={isLast} onClick={onClick}>
              {label}
            </S.Breadcrumb>
            {!isLast && <S.Breadcrumb>/</S.Breadcrumb>}
          </Fragment>
        );
      })}
    </S.Wrapper>
  );
};

export default Breadcrumbs;
