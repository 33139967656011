import { useState } from 'react';
import { useAccount } from 'wagmi';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { getMinimizedStr } from 'components/CopyToClipboard/CopyToClipboard';
import Button from 'components/Button';
import AccoutModal from 'components/AccoutModal';
import WalletProviderModal from 'components/WalletProviderModal';
import logoSrc from 'assets/images/crisiumLogo2.svg';
import MobileHeader from './components/MobileHeader';
import { ROUTES } from './Header.constants';
import * as S from './Header.styles';

const Header = () => {
  const [showWalletProvider, setShowWalletProvider] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const { address } = useAccount();
  const theme = useTheme();
  const navigate = useNavigate();

  const openWalletProvider = () => setShowWalletProvider(true);
  const closeWalletProvider = () => setShowWalletProvider(false);
  const openAccount = () => setShowAccount(true);
  const closeAccount = () => setShowAccount(false);

  const openModal = () => (address ? openAccount() : openWalletProvider());

  const isMobile = window.innerWidth <= theme.breakpoints.lg;

  if (isMobile) {
    return (
      <>
        <MobileHeader onConnectClick={openModal} />
        <WalletProviderModal isOpen={showWalletProvider} onClose={closeWalletProvider} />
        <AccoutModal isOpen={showAccount} onClose={closeAccount} />
      </>
    );
  }

  return (
    <S.Wrapper>
      <S.Logo src={logoSrc} alt="Crisium Logo" width={110} onClick={() => navigate('/')} />

      <S.Navigation>
        {ROUTES.map(({ to, label }) => (
          <S.Link to={to} key={to}>
            {({ isActive }) => <S.NavigationItem isActive={isActive}>{label}</S.NavigationItem>}
          </S.Link>
        ))}
      </S.Navigation>

      <S.RightBlock>
        <S.Chain />
        {address && <S.UserIcon icon="user-circle" onClick={() => navigate('/user')} />}
        <Button onClick={openModal}>
          {address ? getMinimizedStr({ str: address, maxChars: 12 }) : 'Connect'}
        </Button>
      </S.RightBlock>

      <WalletProviderModal isOpen={showWalletProvider} onClose={closeWalletProvider} />
      <AccoutModal isOpen={showAccount} onClose={closeAccount} />
    </S.Wrapper>
  );
};

export default Header;
