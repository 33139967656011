import Button from 'components/Button';

import Modal, { ModalActions, ModalContent, ModalTitle } from 'components/Modal';
import * as S from './ComingSoonModal.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ComingSoonModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="40prc">
      <ModalTitle>Coming soon</ModalTitle>

      <ModalContent>
        <S.Text>
          Coming this month! Join
          <S.Link>
            <S.DiscordIcon icon="discord" height={24} width={24} />
            Discord
          </S.Link>
          to know when
        </S.Text>
      </ModalContent>

      <ModalActions>
        <Button variant="outline" onClick={onClose}>
          Close
        </Button>
      </ModalActions>
    </Modal>
  );
};
