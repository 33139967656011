import { createClient, configureChains, chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

const { chains, provider, webSocketProvider } = configureChains(
  [chain.polygon, chain.polygonMumbai, chain.goerli],
  [publicProvider()]
);

export const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: 'crisium' },
    }),
    // new WalletConnectConnector({
    //   chains,
    //   options: {
    //     qrcode: true,
    //     rpc: {
    //       137: 'https://polygon-rpc.com',
    //       80001: 'https://rpc-mumbai.matic.today',
    //     },
    //   },
    // }),
  ],
  provider,
  webSocketProvider,
});

export { chains };
