import { Suptitle } from 'pages/Home/Home.styles';
import * as S from './TicketBreakdown.styles';
import { TBody, TD, THead, TR } from 'components/Table';
import bronzeTicketSrc from 'assets/images/home/mystery-bronze-ticket.png';
import goldTicketSrc from 'assets/images/home/mystery-gold-ticket.png';
import silverTicketSrc from 'assets/images/home/mystery-silver-ticket.png';

const TABLE_DATA = [
  {
    rowTitle: 'Mint Price',
    values: [{ value: '?' }, { value: '?' }, { value: '?' }],
  },
  {
    rowTitle: 'Total Supply',
    values: [{ value: '10,000' }, { value: '350' }, { value: '50' }],
  },
  {
    rowTitle: 'Printed Ticket Mailed',
    values: [{ value: 'Yes' }, { value: 'Yes' }, { value: 'Yes' }],
  },
  {
    rowTitle: 'Get your Ticket to the Moon',
    values: [{ value: 'Yes' }, { value: 'Yes, Patron Status' }, { value: 'Yes, Benefactor Status' }],
  },
  // {
  //   rowTitle: 'Lottery Prizes Chance* **',
  //   values: [{ value: '3%' }, { value: '35%' }, { value: '82%' }],
  // },
];

export const TicketBreakdown = () => {
  return (
    <S.Wrapper>
      <Suptitle>How it works</Suptitle>
      <S.SectionTitle>THE TICKETS BREAKDOWN</S.SectionTitle>

      <S.StyledTable>
        <THead>
          <S.StyledTH>Details</S.StyledTH>
          <S.StyledTH>
            Bronze
            <S.Ticket src={bronzeTicketSrc}></S.Ticket>
          </S.StyledTH>
          <S.StyledTH>
            Silver <S.Ticket src={silverTicketSrc}></S.Ticket>
          </S.StyledTH>
          <S.StyledTH>
            Gold <S.Ticket src={goldTicketSrc}></S.Ticket>
          </S.StyledTH>
        </THead>
        <TBody>
          {TABLE_DATA.map(({ rowTitle, values }) => {
            return (
              <TR key={rowTitle}>
                <TD>
                  <b>{rowTitle}</b>
                </TD>
                {values.map(({ value }, idx) => (
                  <TD key={value + idx}>{value}</TD>
                ))}
              </TR>
            );
          })}
        </TBody>
      </S.StyledTable>

      {/* <S.Details>
        *For certain experiential prizes such as attending the launch event or visiting Houston HQ, you must
        be a resident of a permissible country and pass KYC.
        <br />
        **There are many different prizes that will be lotteried. The numbers above are expected lowest level
        lotteried prize. Some prizes are more exclusive.
      </S.Details> */}
    </S.Wrapper>
  );
};
