import { Listbox } from '@headlessui/react';
import { ElementType, Fragment, ReactNode } from 'react';

import * as S from './Select.styles';

export interface Option<T> {
  value: T;
  label: string;
}

interface Props<T> {
  className?: string;
  value: T;
  options: Option<T>[];
  onChange: (value: T) => void;
  renderSelected?: (label?: string) => ReactNode;
  ListBox?: ElementType<any>;
  SelectedStartSlot?: ReactNode;
}

const Select = <T extends string>({
  className,
  ListBox,
  SelectedStartSlot,
  value,
  options,
  onChange,
}: Props<T>) => {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Listbox value={value} onChange={onChange} as={ListBox || S.ListBox} className={className}>
      <Listbox.Button as="div">
        <S.SelectedOption className="selected-option">
          {SelectedStartSlot}
          {selectedOption?.label} <S.ArrowDownIcon icon="chevron-down" width={20} height={20} />
        </S.SelectedOption>
      </Listbox.Button>
      <Listbox.Options as={S.OptionList}>
        {options.map(({ label, value }) => (
          <Listbox.Option key={value} value={value} as={Fragment}>
            {({ selected }) => <S.Option selected={selected}>{label}</S.Option>}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export default Select;
