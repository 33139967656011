import ticketImg from 'assets/images/home/ticket.png';
import Button from 'components/Button';
import * as S from './NameToMoon.styles';

const NameToMoon = () => {
  return (
    <S.Wrapper>
      <S.BgImage />
      <S.WrapperInner>
        <S.Content>
          <S.SectionTitle>
            Get your Ticket <br />
            to the moon!
          </S.SectionTitle>
          <S.Text>
            Ticket holders can be the first human beings to own an NFT on the Moon. Intuitive Machines is
            loading this special set of tickets and their corresponding images into the computer onboard the
            Nova-C spacecraft where it will remain in perpetuity on the surface of the moon.
          </S.Text>

          <S.ButtonBlock>
            <Button variant="gradient" onClick={() => window.open('https://discord.gg/ZdRHKN4m', '_blank')}>
              Join Discord
            </Button>
            <Button
              variant="outline"
              onClick={() => window.open('https://twitter.com/CrisiumSpace', '_blank')}
            >
              Follow on X
            </Button>
          </S.ButtonBlock>
        </S.Content>
        <S.Imagery>
          <S.TicketImage src={ticketImg} />
        </S.Imagery>
      </S.WrapperInner>
    </S.Wrapper>
  );
};

export default NameToMoon;
