import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 140px;
  margin-bottom: 48px;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 100px;
  }
`;

export const Image = styled.img`
  max-width: 250px;
  max-height: 250px;
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 220px;
    max-height: 220px;
  }
`;

export const SectionTitle = styled.div`
  text-align: center;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 14px;
  font-weight: bold;
  letter-spacing: 4px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 26px;
  }
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  max-width: 600px;
  text-align: center;
  margin-bottom: 24px;
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
