import ChainInfo from 'components/ChainInfo';
import Hamburger from 'components/Hamburger';
import Icon from 'components/Icon';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import hexToRgba from 'utils/hexToRgba';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 0 40px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${hexToRgba('#070311', 0.8)};

  /* margin-left: 80px; */

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 20px;
    height: 60px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0 10px;
  }
`;

export const Navigation = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
  justify-content: center;
`;

export const NavigationItem = styled.li<{ isActive?: boolean }>`
  list-style: none;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 0.15s linear;

  &::after {
    content: '';
    border-bottom: 0px solid ${({ theme }) => theme.colors.primary.main};
    opacity: 0;
    transition: border-bottom-width 0.15s linear, opacity 0.15s linear;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    width: 100%;
    position: absolute;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.white};

      &::after {
        opacity: 1;
        border-bottom-width: 2px;
      }
    `}
`;

export const Link = styled(NavLink)`
  color: ${({ theme }) => theme.colors.text.light};
  transition: color 0.15s linear;
  text-decoration: none;
  height: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const RightBlock = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
`;

export const InfoIcon = styled(Icon)`
  margin-right: 4px;
`;

export const Chain = styled(ChainInfo)`
  position: absolute;
  left: -170px;
  max-width: 150px;
`;

export const UserIcon = styled(Icon)`
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
    fill: ${({ theme }) => theme.colors.white};
    transition: fill 0.15s linear;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary.blue};
    }
  }
`;

export const Logo = styled.img`
  cursor: pointer;
`;

export const HamburgerBtn = styled(Hamburger)`
  z-index: 3;
`;
