import { createContext, FC, useState } from 'react';

interface ThemeContextProps {
  isLightTheme: boolean;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextProps>({ isLightTheme: true, toggleTheme: () => {} });

const ThemeContextProvider: FC = ({ children }) => {
  const [isLightTheme, setIsLightTheme] = useState(true);

  const toggleTheme = () => setIsLightTheme(!isLightTheme);

  return <ThemeContext.Provider value={{ isLightTheme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeContextProvider;
