import styled from 'styled-components';
import heroImage from 'assets/images/home/hero.png';
export const Wrapper = styled.div`
  scroll-behavior: smooth;
`;

export const HeroSection = styled.div`
  height: calc(100vh - 74px);
  margin: -40px;
  background-image: url(${heroImage});
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-position: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: -30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin: -20px;
    height: calc(100vh - 60px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin: -20px -10px;
  }
`;

export const LogoLarge = styled.img`
  min-width: 200px;
  min-height: 70px;
  max-height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 140px;
    min-height: 30px;
    max-height: 50px;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 40px;
  scroll-behavior: smooth;
`;

export const LearnMore = styled.div`
  display: block;
  font-family: 'Montserrat';
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: 16px;
  transition: color 0.15s linear;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.light};
  }
`;

export const Suptitle = styled.div`
  font-family: 'Montserrat';
  color: ${({ theme }) => theme.colors.primary.blue};
  margin-bottom: 10px;
`;

export const IMText = styled.div`
  font-size: 12px;
  color: #000;
  font-family: 'Montserrat';
  position: absolute;
  right: 10px;
  bottom: 10px;
`;
