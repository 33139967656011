import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';
import { Props } from './Button';

export const Button = styled.button<Props>`
  position: relative;
  user-select: none;
  overflow: hidden;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'LEMON MILK';
  height: 44px;
  padding: 0 24px;
  font-size: 14px;
  transition: background-color 0.15s linear, color 0.15s linear, border-color 0.15s linear;

  ${({ theme, variant }) => {
    if (variant === 'filled') {
      return css`
        background-color: ${theme.colors.primary.main};
        color: ${theme.colors.white};
      `;
    }
    if (variant === 'text') {
      return css`
        background-color: transparent;
        color: ${darken(0.15, theme.colors.white)};
      `;
    }
    if (variant === 'outline') {
      return css`
        background-color: transparent;
        border: 1px solid ${theme.colors.primary.main};
        color: ${darken(0.15, theme.colors.white)};
      `;
    }
    if (variant === 'gradient') {
      return css`
        transition: all 0.15s linear;
        background: linear-gradient(
          270deg,
          ${theme.colors.primary.main} 0%,
          ${theme.colors.primary.blue} 100%
        );
        color: ${darken(0.1, theme.colors.white)};
      `;
    }
  }}

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${({ theme }) => lighten(0.2, theme.colors.primary.main)};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    ${({ theme, variant }) => {
      if (variant === 'filled') {
        return css`
          background-color: ${darken(0.1, theme.colors.primary.main)};
        `;
      }
      if (variant === 'text') {
        return css`
          color: ${theme.colors.white};
        `;
      }
      if (variant === 'outline') {
        return css`
          background-color: transparent;
          color: ${theme.colors.white};
          border: 1px solid ${darken(0.1, theme.colors.primary.main)};
        `;
      }
      if (variant === 'gradient') {
        return css`
          background: linear-gradient(
            270deg,
            ${theme.colors.primary.main} 0%,
            ${theme.colors.primary.blue} 100%
          );
          color: ${theme.colors.white};
        `;
      }
    }}
  }
`;
