import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 180px);
`;

export const Text = styled.div`
  margin-bottom: 20px;
`;

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Text>page not found</Text>
      <Button onClick={() => navigate('/marketplace')}>Go to marketplace</Button>
    </Wrapper>
  );
};

export default NotFound;
