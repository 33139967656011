import styled from 'styled-components';

import Icon from 'components/Icon';

export const CopyIcon = styled(Icon)`
  margin-left: 10px;
  svg {
    stroke: ${({ theme }) => theme.colors.icon.light};
    transition: stroke 0.15s linear;
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.primary.blue};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;
