import styled from 'styled-components';
import skyBg from 'assets/images/skyBg.png';
import linesTop from 'assets/images/linesTop.svg';

export const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-image: url(${skyBg});
  background-size: cover;
`;

export const TopLight = styled.div`
  z-index: -1;
  position: absolute;
  top: -380px;
  left: -310px;
  height: 700px;
  width: 700px;
  background: radial-gradient(31% 48% at 50% 50%, rgba(44, 162, 211, 0.21) 0%, rgba(44, 162, 211, 0) 100%);
  transform: rotate(-31.96deg);
`;

export const BottomLeftLight = styled.div`
  z-index: -1;
  background: radial-gradient(31% 48% at 50% 50%, rgba(44, 162, 211, 0.11) 0%, rgba(44, 162, 211, 0) 100%);
  transform: rotate(125deg);
  position: absolute;
  bottom: -270px;
  left: -170px;
  height: 700px;
  width: 700px;
`;

export const MiddleRightLight = styled.div`
  z-index: -1;
  position: absolute;
  background: radial-gradient(31.3% 48.84% at 50% 50%, rgba(89, 49, 176, 0.2) 0%, rgba(44, 91, 211, 0) 100%);
  opacity: 0.7;
  top: 50%;
  transform: translateY(-50%);
  right: -360px;
  height: 1000px;
  width: 1000px;
`;

export const TopLines = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  right: -390px;
  background-image: url(${linesTop});
  background-repeat: no-repeat;
  width: 896px;
  height: 872px;
`;

export const BottomLines = styled.div`
  z-index: -1;
  position: absolute;
  left: -450px;
  bottom: 0;
  background-image: url(${linesTop});
  background-repeat: no-repeat;
  width: 896px;
  height: 872px;
  transform: rotate(180deg);
  opacity: 0.6;
`;

export const BottomLines2 = styled.div`
  background-image: url(${linesTop});
  background-repeat: no-repeat;
  width: 896px;
  height: 872px;
  transform: rotate(180deg);
  opacity: 0.6;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw;
`;

export const BgWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 1010px;
  z-index: -1;
  left: -450px;
`;
