import CurrencySwitcher from 'components/CurrencySwitcher';
import Input from 'components/Input';
import styled from 'styled-components';

export const Offer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const OfferInput = styled(Input)`
  flex-grow: 2;
`;

export const Currency = styled(CurrencySwitcher)`
  flex-grow: 1;
`;

export const OffetAmount = styled.div`
  margin-bottom: 16px;
`;
