import styled from 'styled-components';

export const Wallet = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  color: ${({ theme }) => theme.colors.text.light};
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 72px;
  padding: 0 22px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: border-color 0.15s linear, color 0.15s linear;
  margin-bottom: 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
  }
`;

export const LogoImg = styled.img`
  margin-right: 10px;
  max-height: 35px;
  max-width: 35px;
`;
