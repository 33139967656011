import * as S from './Terms.styles';

export const Terms = () => {
  return (
    <S.Wrapper>
      <S.Header>TERMS & CONDITIONS - NFT END USER PURCHASE AND LICENSE AGREEMENT</S.Header>

      <S.P>
        This NFT End User Purchase and License Agreement (this “Agreement”) is a legally binding agreement by
        and between Crisium Foundation Ltd. (“Licensee” or “us”) and any owner of any Crisium NFT (defined
        below) (“you” or “Purchaser”). Licensee and each Purchaser may be referred to throughout this
        Agreement collectively as the “Parties” or individually as a “Party”. By purchasing or otherwise
        owning a Crisium NFT, you acknowledge that you have carefully read and agree to the terms of this
        Agreement.
      </S.P>

      <S.P>
        <b>
          WHEN YOU AGREE TO THESE TERMS, YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE
          BETWEEN YOU AND LICENSEE THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW
          CAREFULLY SECTIONS 14 “DISPUTE RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION. HOWEVER, IF YOU
          ARE A RESIDENT OF A JURISDICTION WHERE APPLICABLE LAW PROHIBITS ARBITRATION OF DISPUTES, THE
          AGREEMENT TO ARBITRATE IN SECTION 13 WILL NOT APPLY TO YOU BUT THE PROVISIONS OF SECTION 15
          (GOVERNING LAW) WILL APPLY INSTEAD.
        </b>
      </S.P>

      <S.P reducedBottomSpace>
        1. Agreement to Terms. “Crisium NFT” refers to a non-fungible token (NFT) (i.e., a controllable
        electronic record recorded on a blockchain) that, as of its genesis issuance, is linked to an image of
        Art, as applicable. “Art” means the digital artwork featuring 3D renderings of bracelet or wrist band
        linked to a particular NFT. For avoidance of doubt, the Art is digital in nature and does not include,
        is not linked to, and is not sold together with, any items or representations that have physical
        dimensions such as mass or volume.
      </S.P>
      <S.P margin="20px 0 20px 20px">
        (a) Additional Terms: Crisium NFTs may be available for purchase on one or more platforms, such as
        OpenSea (each, a “NFT Marketplace”), which is not owned or operated by us. The access and use of the
        NFT Marketplace are subject to the separate terms of the NFT Marketplace. In addition, Licensee may
        make various programs or benefits available to owners of Crisium NFTs, which will be subject to
        separate terms as Licensee may promulgate from time to time.
      </S.P>

      <S.P reducedBottomSpace>
        <b>2. Ownership of Crisium NFT.</b>
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (a) When Purchaser acquires a Crisium NFT, Purchaser owns all personal property rights to that Crisium
        NFT (e.g., the right to freely sell, transfer, or otherwise dispose of that Crisium NFT). Such rights,
        however, do not include the ownership of the intellectual property rights in the Art. Such rights are
        licensed pursuant to Section 3 below.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (b) Purchaser represents and warrants that it will not transfer a Crisium NFT in any subsequent
        transaction to a Transferee that is (i) located in a country that is subject to a U.S. Government
        embargo, or that has been designated by the U.S. Government as a terrorist-supporting country; or is
        (ii) listed on any U.S. Government list of prohibited or restricted parties (“Prohibited
        Transferees”). A “Secondary Transaction” means any transaction in which a Crisium NFT is sold by one
        owner to another owner after the initial issuance of the Crisium NFT.
      </S.P>

      <S.P reducedBottomSpace>
        <b>3. License.</b>
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (a) Non-Commercial License. Subject to your compliance with this Agreement, Licensee (or its
        licensors) hereby grants to you, for so long as you own a Crisium NFT (as recorded on the relevant
        blockchain), a non-exclusive, worldwide, royalty-free, revocable license, with no right to sublicense,
        to use, copy, display the Art linked to your purchased Crisium NFT for the following purposes: (i) for
        your own personal, non-commercial use (e.g., personal display), including to create a reasonable
        number of back-up copies and a physical print out, each to be retained only for so long as you own the
        associated Crisium NFT; (ii) to sell or otherwise transfer the associated Crisium NFT consistent with
        the ownership of it (e.g., posting a sales listing on an NFT marketplace); and (iii) to use it in
        connection with any offering compatible with the Crisium NFT. Each Crisium NFT may be compatible with,
        or support entitlements offered by Licensee or a third party. Such entitlements may be provided to you
        pursuant to separate terms and conditions.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (b) Transfer. The licenses in Section 3 are non-transferrable, except that they will automatically
        transfer in connection with a permitted transfer of the Crisium NFT.
      </S.P>

      <S.P reducedBottomSpace>
        <b>4. Reservation of Rights.</b>
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (a) General. All rights in and to the Art not expressly provided for in this Agreement are hereby
        reserved by Licensee. The Art is licensed, not sold. Licensee or its licensors owns and will retain
        all title, interest, ownership rights and intellectual property rights in and to the Art. Without
        limitation, Purchaser shall not, nor permit any third party to do or attempt to, do any of the
        following without express prior written consent from Licensee or its licensors: (i) modify the Art
        except as expressly permitted herein; (ii) use the Art as a brand or trademark or to advertise,
        market, or sell any product or service; (iii) use the Art in connection with media or merchandise that
        promotes hate speech, illegal activities, vulgarity (e.g., pornography), offensive behavior, violence,
        cruelty or political statements, or would otherwise be deemed offensive to the community in Licensee
        or its licensors’ sole discretion; (iv) attempt to register any trademark, copyright, or otherwise
        acquire additional intellectual property rights in or to the Art; (vi) make defamatory or dishonest
        statements about Licensee, Licensee’s licensors, or the Crisium NFTs or otherwise damage the goodwill,
        value or reputation of Licensee, Licensee’s licensors, or the Crisium NFTs; or (vii) use the Art in a
        manner that infringes any third party’s intellectual property, publicity, privacy, or other right.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (b) No Rights to Trademarks. For avoidance of doubt, the licenses in Section 3 do not include the
        right to, and you may not, use any Licensee or any of its licensors trademarks (e.g. Licensee, an
        Licensee licensor, or the names of any Crisium NFTs or any other trademarks or logos that are
        proprietary and included in the Art). No trademark or other rights based on designation of source or
        origin are licensed to you. You may not use or attempt to register any asset, including any domain
        names, social media accounts or related addresses, that contains or incorporates any artwork, other
        representation, name or mark that may be confusingly similar to such trademarks.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (c) Enforcement. Licensee and its licensors reserve the exclusive right to: (i) register and otherwise
        protect all intellectual property and other rights in the Art and in any Licensee or its licensors’
        trademarks and (ii) enforce the intellectual property rights in the Art and in any Licensee and its
        licensors’ trademarks.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (d) Injunctive Relief. Purchaser hereby agrees that a breach or threatened breach of Sections 3 or 4
        of this Agreement by Purchaser would cause Licensee or it’s licensors to suffer irreparable harm and
        that Licensee and/or it’s licensors will therefore be entitled to seek equitable relief to enforce
        this Agreement.
      </S.P>

      <S.P reducedBottomSpace>
        <b>5. Transfers and Secondary Transactions.</b> All Secondary Transactions of the Crisium NFTs are
        subject to the following terms: (a) the Crisium NFT transferee (the “Transferee”) shall, by
        purchasing, accepting, accessing or otherwise using the Crisium NFT or Art, be deemed to accept all of
        the terms of this Agreement as a “Purchaser” hereof; and (b) Licensee or its licensors shall be
        entitled to charge a transaction fee on any Secondary Transactions. Purchaser further acknowledges and
        agrees that all subsequent transactions of the Crisium NFT will be effected on the blockchain network
        governing the Crisium NFT, and Purchaser will be obligated to condition any Transferee’s acquisition
        of Crisium NFT on acceptance of and adherence to this Agreement.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (a) Without limitation of any other termination rights, Licensee may suspend or terminate the license
        to the Art if it has a reasonable basis for believing that You have engaged in an off-chain sale of
        the Crisium NFT, or otherwise engaged in any off-chain transactions for the purchase or sale of the
        Crisium NFT without paying the applicable transaction fees on Secondary Transactions. You, and all
        subsequent Transferees, shall be responsible for paying the applicable transaction fees on Secondary
        Transactions purchase price, regardless of where such purchase price is fulfilled on- chain,
        off-chain, or in a combination of the foregoing.
      </S.P>

      <S.P reducedBottomSpace>
        <b>6. Licensee’ Rights and Obligations to the Art and Crisium NFTs.</b>Licensee acknowledge and agree
        that Licensee is responsible for repairing, supporting, replacing, or maintaining the website hosting
        the Art or other applications or entitlements which the Crisium NFT is compatible with, and Licensee
        has the obligation to maintain any connection or link between a Crisium NFT and the corresponding Art.
      </S.P>

      <S.P reducedBottomSpace>
        <b>7. Warranty Disclaimers and Assumption of Risk.</b> Purchaser represents and warrants that it (a)
        is the age of majority in Purchaser’s place of residence (which is typically 18 years of age in most
        U.S. states) and has the legal capacity to enter into this Agreement, (b) that Purchaser will use and
        interact with the Crisium NFTs and Art only for lawful purposes and in accordance with this Agreement,
        and (c) that Purchaser will not use the Crisium NFTs or Art to violate (and Purchaser will otherwise
        not violate) any law, regulation or ordinance or any right of Licensee, its licensors or any third
        party, including without limitation, any right of privacy, publicity, copyright, trademark, or patent.
      </S.P>

      <S.P reducedBottomSpace>
        THE CRISIUM NFTS ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING,
        LICENSEE AND ITS LICENSORS EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
        A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE
        OF DEALING OR USAGE OF TRADE. LICENSEE AND ITS LICENSORS MAKE NO WARRANTY THAT THE CRISIUM NFTS WILL
        MEET PURCHASER’S REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS.
        LICENSEE AND ITS LICENSORS MAKE NO WARRANTY REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS,
        COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR CONTENT ON THE CRISIUM NFTS.
      </S.P>

      <S.P reducedBottomSpace>
        LICENSEE AND ITS LICENSORS WILL NOT BE RESPONSIBLE OR LIABLE TO PURCHASER FOR ANY LOSS AND TAKES NO
        RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO PURCHASER FOR, ANY USE OF THE CRISIUM NFT, INCLUDING BUT
        NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
        INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED WALLET ADDRESSES; (II) SERVER FAILURE OR DATA LOSS;
        (III) CORRUPTED CRYPTOCURRENCY WALLET FILES; (IV) UNAUTHORIZED ACCESS TO THE CRISIUM NFT; OR (V) ANY
        UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING,
        BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST BLOCKCHAIN NETWORK UNDERLYING THE CRISIUM NFTS.
      </S.P>
      <S.P reducedBottomSpace>
        THE CRISIUM NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD
        MAINTAINED IN THE APPLICABLE BLOCKCHAIN NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE
        DIGITAL ASSET OCCURS ON THE DISTRIBUTED LEDGER WITHIN SUCH BLOCKCHAIN NETWORK, WHICH LICENSEE DOES NOT
        CONTROL. LICENSEE DOES NOT GUARANTEE THAT LICENSEE CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY
        CRISIUM NFT. PURCHASER BEARS FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY, LEGITIMACY, AND
        AUTHENTICITY OF ASSETS PURCHASER PURCHASES THROUGH THE NFT MARKETPLACE. NOTWITHSTANDING INDICATORS AND
        MESSAGES THAT SUGGEST VERIFICATION, LICENSEE MAKES NO CLAIMS ABOUT THE IDENTITY, LEGITIMACY, OR
        AUTHENTICITY OF ASSETS ON THE NFT MARKETPLACE OR ANY PURPORTED SUBSEQUENT TRANSACTIONS.
      </S.P>
      <S.P reducedBottomSpace>
        LICENSEE AND ITS LICENSORS ARE NOT RESPONSIBLE ANY KIND OF FAILURE, ABNORMAL BEHAVIOR OF SOFTWARE
        (E.G., WALLET, SMART CONTRACT), BLOCKCHAINS OR ANY OTHER FEATURES OF THE CRISIUM NFTS. LICENSEE AND
        ITS LICENSORS ARE NOT RESPONSIBLE FOR CASUALTIES DUE TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES
        (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE CRISIUM NFTS, INCLUDING FORKS,
        TECHNICAL NODE ISSUES OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
      </S.P>
      <S.P>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO
        THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
      </S.P>

      <S.P reducedBottomSpace>
        <b>8. Assumption of Risk.</b> Purchaser accepts and acknowledges all risks associated with the
        following:
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (a) Purchasers will be responsible for NFT “gas” costs. Purchaser is solely responsible for
        determining what, if any, taxes apply to Purchaser’s purchase, sale, or transfer of the Crisium NFTs.
        Licensee is not responsible for determining or paying the taxes that apply to such transactions.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (b) Licensee does not store, send, or receive cryptocurrency assets. Any transfer of cryptocurrency
        assets occurs within the supporting blockchain that is not controlled by Licensee. Transactions of the
        Crisium NFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental
        transactions may not be recoverable. Some transactions of the Crisium NFTs shall be deemed to be made
        when recorded on a public blockchain ledger, which is not necessarily the date or time that Purchaser
        initiated the transaction.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (c) There are risks associated with using an Internet based digital asset, including but not limited
        to, the risk of hardware, software and Internet connections, the risk of malicious software
        introduction, and the risk that third parties may obtain unauthorized access to information stored
        within your Wallet. Licensee will not be responsible for any communication failures, disruptions,
        errors, distortions or delays you may experience when effecting Crisium NFT transactions, however
        caused.
      </S.P>
      <S.P margin="20px 0 20px 20px">
        (d) Transactions involving the Crisium NFTs may rely on third-party platforms to perform transactions.
        If Licensee is unable to maintain a good relationship with such platform providers; if the terms and
        conditions or pricing of such platform providers change; if Licensee violates or cannot comply with
        the terms and conditions of such platforms; or if any of such platforms loses market share or falls
        out of favor or is unavailable for a prolonged period of time, access to and interactions of the
        Crisium NFTs will suffer.
      </S.P>

      <S.P>
        <b>9. Links to Third Party Websites or Resources.</b> Use and interaction of the Crisium NFTs and Art
        may allow Purchaser to access third-party websites or other resources. Licensee provides access only
        as a convenience and is not responsible for the content, products or services on or available from
        those resources or links displayed on such websites. Purchaser acknowledges sole responsibility for
        and assumes all risk arising from Purchaser’s use of any third-party resources. Under no circumstances
        shall Purchaser’s inability to view Art on a third-party website serve as grounds for a claim against
        Licensee.
      </S.P>

      <S.P>
        <b>10. Termination of License to Art.</b> Purchaser’s licenses to the Art shall automatically
        terminate and all rights shall revert to Licensee if at any time: (a) Purchaser breaches any portion
        of this Agreement or (b) Purchaser engages in any unlawful activity related to the Crisium NFT
        (including transferring the Crisium NFT to a Prohibited Transferee). Upon any termination,
        discontinuation or cancellation of Purchaser’s licenses to the Art, Licensee may disable Purchaser’s
        access to the Art and Purchaser shall delete, remove, or otherwise destroy any back up or single
        digital or physical copy of the Art. Upon any termination, discontinuation or cancellation of the
        license in this Agreement, the following Sections will survive: 2, 4 through 15.
      </S.P>

      <S.P>
        <b>11. Indemnity. </b>Purchaser shall defend, indemnify, and hold Licensee, its licensors and
        affiliates (the “Indemnified Parties”) harmless from and against any and all claims, damages, losses,
        costs, investigations, liabilities, judgments, fines, penalties, settlements, interest, and expenses
        (including attorneys’ fees) that directly or indirectly arise from or are related to any claim, suit,
        action, demand, or proceeding made or brought by a third party (including any person who accesses or
        transacts using the Crisium NFTs whether or not such person personally purchased the Crisium NFTs)
        against the Indemnified Parties, or on account of the investigation, defense, or settlement thereof,
        arising out of or in connection with (a) your access to or use of the NFT Marketplace, (b) your breach
        of this Agreement, and (c) your exercise of the licenses in Section 3.
      </S.P>

      <S.P reducedBottomSpace>
        <b>12. Limitation of Liability.</b>
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (a) TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER LICENSEE NOR ITS LICENSORS OR SERVICE PROVIDERS,
        INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE CRISIUM NFTS WILL BE LIABLE FOR ANY INCIDENTAL,
        SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS,
        LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM
        FAILURE OR THE COST OF SUBSTITUTE CRISIUM NFTS OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE
        TERMS OR ANY CRISIUM NFT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
        LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT LICENSEE OR ITS SERVICE PROVIDERS HAS BEEN
        INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE
        FAILED OF ITS ESSENTIAL PURPOSE.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (b) TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE JURISDICTION, IN NO EVENT WILL
        LICENSEE’S OR ITS LICENSORS’ OR SERVICE PROVIDERS’ TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION
        WITH THESE TERMS OR ANY Crisium NFT OR ACCESS THE ART EXCEED $500.
      </S.P>
      <S.P margin="20px 0 20px 20px">
        (c) THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF
        THE BARGAIN BETWEEN LICENSEE AND PURCHASER.
      </S.P>

      <S.P>
        <b>13. Governing Law and Forum Choice. </b> This Agreement and any action related thereto will be
        governed by the laws of California, without regard to its conflict of laws provisions. Except as
        otherwise expressly set forth in Section 14 “Dispute Resolution,” the exclusive jurisdiction for all
        Disputes (defined below) that Purchaser and Licensee are not required to arbitrate will be the courts
        located in Los Angeles County, California, and Purchaser and Licensee each waive any objection to
        jurisdiction and venue in such courts.
      </S.P>

      <S.P reducedBottomSpace>
        <b>14. DisputeResolution.</b>
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (a) <u>Mandatory Arbitration of Disputes</u>. The Parties each agree that any dispute, claim or
        controversy arising out of or relating to these Terms or the breach, termination, enforcement,
        interpretation or validity thereof of the Crisium NFTs transaction (collectively, “Disputes”) will be
        resolved{' '}
        <b>
          solely by binding, individual arbitration and not in a class, representative or consolidated action
          or proceeding.
        </b>
        You and Licensee agree that the Federal Arbitration Act governs the interpretation and enforcement of
        this Agreement, and that you and Licensee are each waiving the right to a trial by jury or to
        participate in a class action. This arbitration provision shall survive termination of these Terms.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (b) <u>Exceptions.</u> As limited exceptions to Section 14(a) above: (i) both Parties may seek to
        resolve a Dispute in small claims court if it qualifies; and (ii) both Parties each retain the right
        to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or
        misappropriation of Licensee (or its licensors’) intellectual property rights.
      </S.P>
      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (c) <u>Conducting Arbitration and Arbitration Rules.</u> The arbitration will be conducted by JAMS
        under its JAMS Comprehensive Arbitration Rules and Procedures (the “JAMS Rules”) then in effect,
        except as modified by these Terms. The JAMS Rules are available at https://www.jamsadr.com/. A party
        who wishes to start arbitration must submit a written Demand for Arbitration to JAMS and give notice
        to the other party as specified in the JAMS Rules. JAMS provides a form Demand for Arbitration at
        https://www.jamsadr.com/.
      </S.P>

      <S.P reducedBottomSpace>
        Any arbitration hearings will take place in the county (or parish) where you live, unless we both
        agree to a different location but any such arbitration will be conducted remotely to the extent
        permitted by the JAMS Rules. The Parties agree that the arbitrator shall have exclusive authority to
        decide all issues relating to the interpretation, applicability, enforceability, and scope of this
        arbitration agreement.
      </S.P>

      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (d) <u>Arbitration Costs.</u> Payment of all filing, administration and arbitrator fees will be
        governed by the JAMS Rules, and Licensee won’t seek to recover the administration and arbitrator fees
        Licensee is responsible for paying, unless the arbitrator finds your Dispute frivolous. If Licensee
        prevails in arbitration Licensee will pay for all of its attorneys’ fees and costs and won’t seek to
        recover them from you. If you prevail in arbitration you will be entitled to an award of attorneys’
        fees and expenses to the extent provided under applicable law.
      </S.P>

      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (e) <u>Injunctive and Declaratory Relief.</u> Except as provided in Section 14(b) above, the
        arbitrator shall determine all issues of liability on the merits of any claim asserted by either party
        and may award declaratory or injunctive relief only in favor of the individual party seeking relief
        and only to the extent necessary to provide relief warranted by that party’s individual claim. To the
        extent that you or Licensee prevail on a claim and seek public injunctive relief (that is, injunctive
        relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future
        injury to the public), the entitlement to and extent of such relief must be litigated in a civil court
        of competent jurisdiction and not in arbitration. The parties agree that litigation of any issues of
        public injunctive relief shall be stayed pending the outcome of the merits of any individual claims in
        arbitration.
      </S.P>

      <S.P margin="20px 0 20px 20px" reducedBottomSpace>
        (f) <u>Class Action Waiver.</u>{' '}
        <b>
          YOU AND LICENSEE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
          CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
          PROCEEDING.
        </b>{' '}
        Further, if the parties’ Dispute is resolved through arbitration, the arbitrator may not consolidate
        another person’s claims with your claims, and may not otherwise preside over any form of a
        representative or class proceeding. If this specific provision is found to be unenforceable, then the
        entirety of this Dispute Resolution section shall be null and void.
      </S.P>
      <S.P margin="20px 0 20px 20px">
        (g) <u>Severability.</u> With the exception of any of the provisions in Section 14(f) of these Terms
        (“Class Action Waiver”), if an arbitrator or court of competent jurisdiction decides that any part of
        these Terms is invalid or unenforceable, the other parts of these Terms will still apply.
      </S.P>

      <S.P>
        <b>15. General Terms.</b> This Agreement will transfer and be binding upon and will inure to the
        benefit of the parties and their permitted successors and assigns, in particular any Transferee. This
        Agreement constitutes the entire agreement, and supersedes any and all prior or contemporaneous
        representations, understandings and agreements, between the Parties with respect to the subject matter
        of this Agreement, all of which are hereby merged into this Agreement. Without limitation, the terms
        of any other document, course of dealing, or course of trade will not modify this Agreement, except as
        expressly provided in this Agreement or as the Parties may agree in writing. No amendment to this
        Agreement or waiver of any provision hereof will be valid or binding unless reduced to writing and
        duly executed by the Party or Parties to be bound thereby. Failure to promptly enforce a provision of
        this Agreement will not be construed as a waiver of such provision. Nothing contained in this
        Agreement will be deemed to create, or be construed as creating, a joint venture or partnership
        between the parties. Neither Party is, by virtue of this Agreement or otherwise, authorized as an
        agent or legal representative of the other Party. Neither Party to this Agreement is granted any right
        or authority to assume or to create any obligation or responsibility, express or implied, on behalf or
        in the name of the other party, or to bind such other Party in any manner. Each of the Parties
        acknowledges that it has had the opportunity to have this Agreement reviewed or not by independent
        legal counsel of its choice. If any one or more of the provisions of this Agreement should be ruled
        wholly or partly invalid or unenforceable, then the provisions held invalid or unenforceable will be
        deemed amended, and the court or other government body is authorized to reform the provision(s) to the
        minimum extent necessary to render them valid and enforceable in conformity with the parties’ intent
        as manifested herein. The headings to Sections of this Agreement are for convenience or reference only
        and do not form a part of this Agreement and will not in any way affect its interpretation. Neither
        Party will be afforded or denied preference in the construction of this Agreement, whether by virtue
        of being the drafter or otherwise. For purposes of this Agreement, the words and phrases “include,”
        “includes”, “including” and “such as” are deemed to be followed by the words “without limitation”.
        Purchaser may give notice to Licensee by contacting Licensee at info@crisium.io. Notice is effective
        upon receipt. The Parties have agreed to contract electronically, and accordingly, electronic
        signatures will be given the same effect and weight as originals. The parties agree that Licensee’s
        licensors are intended third party beneficiaries of this Agreement and may enforce the provisions
        hereof as if it were a party hereto.
      </S.P>
    </S.Wrapper>
  );
};
