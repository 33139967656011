import styled from 'styled-components';

export const Bg = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background.card};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
`;

export const Title = styled.div`
  padding: 20px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 18;
`;
