import * as S from './Home.styles';
import logoLarge from 'assets/images/crisiumLogo2.svg';
import Button from 'components/Button';
import Explore from './components/Explore';
import NameToMoon from './components/NameToMoon';
import About from './components/About';
import Nft from './components/Nft';
import { ComingSoonModal } from './components/ComingSoonModal';
import { useState } from 'react';
import { TicketBreakdown } from './components/TicketBreakdown';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <S.Wrapper>
      <S.HeroSection>
        <S.LogoLarge src={logoLarge} />

        <S.BottomSection>
          <Button variant="gradient" onClick={() => setIsModalOpen(true)}>
            Get your Ticket to the Moon
          </Button>
          <S.LearnMore
            // href="#explore"
            onClick={() => {
              const explore = document.getElementById('explore');
              window.scrollTo({ top: (explore?.offsetTop || 80) - 80, behavior: 'smooth' });
            }}
          >
            Learn more
          </S.LearnMore>
        </S.BottomSection>

        <S.IMText>Intuitive Machines</S.IMText>
      </S.HeroSection>
      <Explore />
      <NameToMoon />
      <About />
      <TicketBreakdown />
      <Nft />

      <ComingSoonModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </S.Wrapper>
  );
};

export default Home;
