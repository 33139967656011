import styled from 'styled-components';
import ReactModal from 'react-modal';
import { ModalSize } from './Modal';
import Icon from 'components/Icon';

export const Modal = styled(ReactModal)<{ size?: ModalSize }>`
  background-color: ${({ theme }) => theme.colors.background.main};
  padding: 30px 30px;
  border-radius: 10px;
  position: relative;
  max-width: ${({ size }) => {
    switch (size) {
      case '30prc':
        return '30%';
      case '40prc':
        return '40%';
      case '60prc':
        return '60%';
      case '80prc':
      default:
        return '80%';
    }
  }};
  top: 50%;
  left: 50%;
  font-size: 14px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.04);
  /* overflow: hidden; */

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: ${({ size }) => {
      switch (size) {
        case '30prc':
        case '40prc':
          return '60%';
        case '60prc':
        case '80prc':
        default:
          return '80%';
      }
    }};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: ${({ size }) => {
      switch (size) {
        case '30prc':
        case '40prc':
        case '60prc':
        case '80prc':
        default:
          return '95%';
      }
    }};
  }
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 50;
  display: inline-flex;
  user-select: none;
  svg {
    stroke: ${({ theme }) => theme.colors.icon.light};
    transition: stroke 0.15s linear;
    height: 30px;
    width: 30px;
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.primary.blue};
    }
  }
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 1.2;
`;

export const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  font-family: 'Montserrat';
  font-size: 14px;
`;
