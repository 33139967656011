import H2 from 'components/H2';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* & > * {
    text-align: left;
  } */
`;

export const P = styled.p<{ reducedBottomSpace?: boolean; margin?: string }>`
  font-size: 14px;
  /* text-align: center; */
  font-family: 'Montserrat';
  margin: ${({ margin }) => margin || '20px 0'};

  ${({ reducedBottomSpace }) =>
    reducedBottomSpace &&
    css`
      margin-bottom: 0;
    `}
`;

export const Header = styled(H2)`
  text-align: center;
`;
