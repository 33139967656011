import styled from 'styled-components';

export const Image = styled.div<{ src: string }>`
  height: 100px;
  width: 100px;
  border-radius: 10px;
  margin-right: 16px;

  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const ItemName = styled.div`
  font-family: 'LEMON MILK';
  font-weight: bold;
  font-size: 18px;
`;

export const Owner = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 10px;
  padding: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    border: none;
  }
`;

export const Price = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary.blue};
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: end;
    margin-left: auto;
  }
`;
