import styled, { css } from 'styled-components';

export const Block = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const Text = styled.div<{ error?: boolean }>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Montserrat';
  font-weight: 600;

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.error.main};
    `}
`;
