import crisiumOctagon from 'assets/images/home/crisiumOctagon.png';
import Button from 'components/Button';
import * as HomeS from '../../Home.styles';
import * as S from './Explore.styles';

const Explore = () => {
  return (
    <S.Wrapper id="explore">
      <S.Image src={crisiumOctagon} />
      <HomeS.Suptitle>Join us</HomeS.Suptitle>
      <S.SectionTitle>
        BRINGING ACCESS TO <br /> THE MOON FOR ALL
      </S.SectionTitle>
      <S.Text>
        Space exploration is not just for the select few, but for all of humanity. With the Tickets to the
        Moon NFT series, Crisium and Intuitive Machines are together opening up access to the Moon to all.
      </S.Text>

      <S.ButtonBlock>
        <Button variant="gradient" onClick={() => window.open('https://discord.gg/ZdRHKN4m', '_blank')}>
          Join Discord
        </Button>
        <Button variant="outline" onClick={() => window.open('https://twitter.com/CrisiumSpace', '_blank')}>
          Follow on X
        </Button>
      </S.ButtonBlock>
    </S.Wrapper>
  );
};

export default Explore;
