import Icon from 'components/Icon';
import Input from 'components/Input';
import Select from 'components/Select';
import styled from 'styled-components';

export const Images = styled.div`
  position: relative;
  margin-bottom: 100px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 70px;
  }
`;

export const ImageBg = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  border-radius: 10px;
  width: 100%;
  height: 200px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 100px;
  }
`;

export const UserIcon = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.white};
  margin: 0 auto;
  position: absolute;
  bottom: -75px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 100px;
    width: 100px;
    bottom: -50px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Name = styled.div`
  font-size: 22px;
  margin-bottom: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 18px;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 14px;
  text-align: center;
  font-family: 'Montserrat';
  max-width: 45vw;
  line-height: 1.2;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    max-width: 50vw;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 80vw;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 92vw;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const SortSelect = styled(Select)`
  min-width: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-grow: 1;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    min-width: auto;
  }
` as typeof Select;

export const SearchInput = styled(Input)`
  min-width: 300px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-grow: 2;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    min-width: auto;
  }
`;

export const SearchIcon = styled(Icon)`
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabContent = styled.div`
  padding: 24px 0;
`;
