import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const SectionTitle = styled.div`
  text-align: left;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 40px;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 26px;
  }
`;

export const Cards = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

export const Card = styled.div`
  max-width: 240px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CardContrainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const CardImage = styled.img`
  max-width: 30px;
`;

export const CardTitle = styled.h4`
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    text-align: center;
  }
`;

export const CardDescription = styled.h4`
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 1.5;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    text-align: center;
  }
`;

export const ButtonBlock = styled.div``;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  max-width: 800px;
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.5;
`;

export const Video = styled.video`
  margin-top: 20px;
  max-height: 500px;
  border-radius: 10px;
  border: 6px solid ${({ theme }) => theme.colors.border.main};
  width: 100%;
  max-width: 360px;
`;
