import { useReactTable, createColumnHelper, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import CopyToClipboard from 'components/CopyToClipboard';
import { Table, TBody, TD, TH, THead, TR } from 'components/Table';

import * as S from './LatestSales.styles';

interface Sale {
  id: string;
  imgSrc: string;
  title: string;
  price: string;
  tokenId: string;
  buyer: string;
  date: string;
}
interface Props {
  latestSales: Sale[];
}

const columnHelper = createColumnHelper<Sale>();

const columns = [
  columnHelper.accessor(({ imgSrc, title }) => ({ imgSrc, title }), {
    header: 'Item',
    minSize: 200,
    cell: (info) => {
      const { imgSrc, title } = info.getValue();

      return (
        <S.Item>
          <S.Image src={imgSrc} />
          {title}
        </S.Item>
      );
    },
  }),
  columnHelper.accessor('price', {
    id: 'price',
    minSize: 100,
    cell: (info) => <>{Number(info.getValue()).toFixed(2)}</>,
    header: 'Price',
  }),
  columnHelper.accessor('tokenId', {
    minSize: 140,
    header: 'Token Id',
  }),
  columnHelper.accessor('buyer', {
    minSize: 200,
    header: 'Buyer',
    cell: (info) => <CopyToClipboard str={info.getValue()} maxChars={12} />,
  }),
  columnHelper.accessor('date', {
    minSize: 200,
    header: 'Date',
    cell: (info) => (
      <S.DateWrapper>
        <S.Date>{DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT)}</S.Date>

        <S.LinkIcon icon="link" />
      </S.DateWrapper>
    ),
  }),
];

const LatestSales = ({ latestSales }: Props) => {
  const table = useReactTable({ data: latestSales, columns, getCoreRowModel: getCoreRowModel() });

  return (
    <S.Wrapper>
      <Table>
        <THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TR key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TH key={header.id} minWidth={header.column.getSize()}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TH>
              ))}
            </TR>
          ))}
        </THead>
        <TBody>
          {table.getRowModel().rows.map((row) => (
            <TR key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TD key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TD>
              ))}
            </TR>
          ))}
        </TBody>
      </Table>
    </S.Wrapper>
  );
};

export default LatestSales;
