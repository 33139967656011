import Modal, { ModalContent, ModalTitle } from 'components/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
}

export const InfoModal = ({ isOpen, onClose, title, description }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>{title}</ModalTitle>
      <ModalContent>{description}</ModalContent>
    </Modal>
  );
};
