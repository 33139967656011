import { Disclosure as LogicDisclosure, Transition } from '@headlessui/react';
import { Fragment, ReactElement, ReactNode } from 'react';

import * as S from './Disclosure.styles';

interface Props {
  SummarySlot: ReactNode;
  ContentSlot?: ReactElement<HTMLDivElement>;
  contentHeight?: number;
  defaultOpen?: boolean;
}

const Disclosure = ({ SummarySlot, ContentSlot, contentHeight, defaultOpen }: Props) => {
  return (
    <LogicDisclosure as={S.Disclosure} contentHeight={contentHeight} defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <LogicDisclosure.Button as={S.DisclosureSummary}>
            {SummarySlot}
            <S.ArrowIcon open={open} icon="chevron-down" />
          </LogicDisclosure.Button>
          <Transition
            as={Fragment}
            show={open}
            enter="transition"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <LogicDisclosure.Panel as={S.DisclosureContent}>{ContentSlot}</LogicDisclosure.Panel>
          </Transition>
        </>
      )}
    </LogicDisclosure>
  );
};

export default Disclosure;
