import Icon from 'components/Icon';
import styled, { css } from 'styled-components';
import hexToRgba from 'utils/hexToRgba';

export const Warning = styled.div<{ error?: boolean }>`
  padding: 10px;
  width: 100%;
  line-height: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Montserrat';

  ${({ theme, error }) => {
    const color = error ? theme.colors.error.main : theme.colors.warning.main;

    return css`
      background-color: ${hexToRgba(color, 0.4)};
      color: ${color};
    `;
  }}
`;

export const InfoIcon = styled(Icon)`
  margin-right: 4px;
`;
