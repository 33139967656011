import styled from 'styled-components';

const H2 = styled.h2`
  font-size: 26px;
  font-family: 'LEMON MILK';
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.15em;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 22px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 18px;
  }
`;

export default H2;
