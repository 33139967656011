import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ opaque?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  ${({ opaque }) =>
    opaque &&
    css`
      opacity: 1;
    `}
  transition: opacity 0.15s linear;
  background-color: ${({ theme }) => theme.colors.background.main};
  min-height: 100vh;
  padding-top: 80px;
  width: 100vw;
  user-select: none;
  pointer-events: none;
  z-index: 1;
`;

export const MainWrapper = styled.div`
  pointer-events: none;
`;
