import { IconType } from 'components/Icon';

export enum Currency {
  USD = 'usd',
  CRISIUM = 'crisium',
  ETH = 'eth',
}

export const CURRENCIES = [
  {
    value: Currency.CRISIUM,
    icon: 'crisium' as IconType,
  },
  {
    value: Currency.ETH,
    icon: 'eth' as IconType,
  },
  {
    value: Currency.USD,
    icon: 'usd' as IconType,
  },
];
