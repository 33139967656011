import styled from 'styled-components';
import * as HomeS from '../../Home.styles';
import ticketsSrc from 'assets/images/home/ticket-group.png';

export const Wrapper = styled.div`
  padding: 60px 0;
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const Imagery = styled.div`
  position: relative;
  flex: 3;

  height: 500px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    flex: 1;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 500px;
    flex: unset;
    width: 100%;
  }
`;

export const Content = styled.div`
  flex: 4;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    flex: 1;
  }
`;

export const Suptitle = styled(HomeS.Suptitle)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: center;
  }
`;

export const CardSmall = styled.img`
  max-width: 140px;

  position: absolute;
  right: 300px;
  top: 290px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    right: auto;
    left: 30px;
  }
`;

export const CardMedium = styled.img`
  max-width: 180px;
  position: absolute;
  right: 290px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    right: auto;
    left: 20px;
  }
`;

export const CardLarge = styled.img`
  max-width: 240px;
  position: absolute;
  right: 30px;
  top: 80px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    right: 0;
  }
`;

export const SectionTitle = styled.div`
  text-align: left;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 14px;
  font-weight: bold;
  letter-spacing: 4px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 26px;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  max-width: 800px;
  text-align: left;
  margin-bottom: 24px;
  line-height: 1.5;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: center;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: flex;
    justify-content: center;
  }
`;

export const TicketsImage = styled.div`
  background-image: url(${ticketsSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
`;
