import styled, { css } from 'styled-components';

import Button from 'components/Button';

export const noInputArrows = css`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ApplyButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`;

export const MinMaxPriceWrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  ${noInputArrows}

  & > * {
    border-width: 0px;
    border-radius: 0;

    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.border.main};
    }
  }
`;

export const ToWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.primary.main};
  background-color: ${({ theme }) => theme.colors.background.main};
`;
