import styled, { css } from 'styled-components';

import H2 from 'components/H2';
import Icon from 'components/Icon';

export const Title = styled(H2)`
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 20px;
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const FilterWrapper = styled.div<{ isFilterOpen?: boolean }>`
  width: 0;
  transition: width 0.15s linear;
  overflow: hidden;
  position: relative;

  ${({ isFilterOpen }) =>
    isFilterOpen &&
    css`
      width: 550px;
      margin-right: 24px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: ${({ isFilterOpen }) => (isFilterOpen ? '330px' : '0')};
    position: fixed;
    transition: width 0.15s linear, left 0.15s linear;
    left: -40px;
    top: 0;
    bottom: 0;
    z-index: 1;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.background.main};
    padding: 20px;
    overflow: hidden;

    ${({ isFilterOpen }) =>
      isFilterOpen &&
      css`
        left: 0;
        max-width: 100%;
        height: 100vh;
      `};
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const CloseIcon = styled(Icon)`
  transform: rotate(90deg);
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.colors.icon.light};
    transition: stroke 0.15s linear;
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.primary.blue};
    }
  }
`;

export const FilterControls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
`;

export const ClearButton = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat';
  transition: color 0.15s linear;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.blue};
  }
`;
