import AccordionItem from 'components/Accordion/components/AccordionItem';
import H2 from 'components/H2';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled(H2)`
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
    /* gap: 16px; */
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    gap: 12px;
  }
`;

export const LeftSide = styled.div`
  flex: 1;
`;
export const RightSide = styled.div`
  flex: 1;
`;

export const FaqItem = styled(AccordionItem)`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
