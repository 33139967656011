import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';

// import mockImgSrc from 'assets/images/imspace.png';
import userBg from 'assets/images/userBg.png';
import avatarBg from 'assets/images/mockAvatar.png';
import CopyToClipboard from 'components/CopyToClipboard';
import Tabs from 'components/Tabs';
import useStateX from 'hooks/useStateX';
import NothingToShow from 'components/NothingToShow';
import { Sort } from 'pages/Marketplace/components/Filters/Filters';

import * as S from './User.styles';
import { useLocalStorage } from 'react-use';
import { MarketItem } from 'pages/MarketplaceItem/MarketplaceItem';
import { CardWrapper } from 'pages/Marketplace/Marketplace.styles';
import Card from 'components/Card';
// import heroImage from 'assets/images/home/hero.png';
import legsSrc from 'assets/images/legs-v2.png';
// import engineImage from 'assets/images/engine2.png';
// import engineSrc from 'assets/images/engine-v2.png';
import core2Src from 'assets/images/core2-v2.png';
import { SneakBackdrop } from 'components/SneakBackdrop';
// import engineImage from 'assets/images/engine-nft-1.png';
// import engineImage from 'assets/images/solar1.png';
// import engineImage from 'assets/images/Engine-1.png';
// import engineImage from 'assets/images/engine.jpeg';

const USER_TABS = [
  { value: 'overview', label: 'Overview' },
  { value: 'listed', label: 'LISTED ON MARKETPLACE' },
];

const SORT_OPTIONS: { value: Sort; label: string }[] = [
  { value: 'newest', label: 'Newest first' },
  { value: 'oldest', label: 'Oldest first' },
];

const arr = [
  {
    title: 'Legs',
    description: 'Legs',
    imgSrc: legsSrc,
    owner: '0x0323323',
    id: '22',
    price: '12.00',
  },
  {
    title: 'Core',
    description: 'Core',
    imgSrc: core2Src,
    owner: '0x0323323',
    id: '100',
    price: '100.00',
    category: 'core',
  },
];

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const User = () => {
  const { address: walletAddress } = useAccount();
  const { address = walletAddress } = useParams();
  const [state, setState] = useStateX({
    activeTab: USER_TABS[0].value,
    sort: SORT_OPTIONS[0].value,
    search: '',
  });
  const [purchased = []] = useLocalStorage<MarketItem[]>('purchased-items');

  const { activeTab, sort, search } = state;

  return (
    <SneakBackdrop messageTitle="User profiles are coming soon." messageDescription="Coming Soon">
      <S.Images>
        <S.ImageBg src={userBg} />
        <S.UserIcon src={avatarBg} />
      </S.Images>
      <S.UserInfo>
        <S.Name>
          <CopyToClipboard str={address || ZERO_ADDRESS} maxChars={12} />
        </S.Name>
        <S.Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sit amet ullamcorper leo, quis
          fermentum ex. Suspendisse potenti.
        </S.Description>
      </S.UserInfo>

      <S.FilterWrapper>
        <Tabs tabs={USER_TABS} value={activeTab} onTabChange={(value) => setState({ activeTab: value })} />

        <S.Filters>
          <S.SortSelect options={SORT_OPTIONS} value={sort} onChange={(value) => setState({ sort: value })} />
          <S.SearchInput
            startSlot={<S.SearchIcon icon="search" />}
            placeholder="Search by name"
            value={search}
            onChange={(e) => setState({ search: e.target.value || '' })}
          />
        </S.Filters>
      </S.FilterWrapper>

      <S.TabContent>
        {activeTab === 'overview' && purchased.length + arr.length > 0 ? (
          <CardWrapper>
            {[...purchased, ...arr].map(({ id, title, imgSrc, description }) => (
              <Card key={id} title={title} imgSrc={imgSrc} description={description} />
            ))}
          </CardWrapper>
        ) : (
          <NothingToShow />
        )}
      </S.TabContent>
    </SneakBackdrop>
  );
};

export default User;
