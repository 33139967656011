import styled, { css } from 'styled-components';
import hexToRgba from 'utils/hexToRgba';

export const Table = styled.table`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: 'Montserrat';
  border-collapse: none;
  text-indent: initial;
  border-spacing: 0;
  overflow: scroll;
  border-radius: 10px;
`;
export const THead = styled.thead`
  font-family: 'Montserrat';
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.light};
  width: 100%;
  height: 50px;
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.table.head};
  border-radius: 10px;
`;
export const TH = styled.th<{ minWidth?: number }>`
  text-align: start;
  padding: 0 20px;
  border: none;
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
`;
export const TBody = styled.tbody`
  background-color: ${({ theme }) => hexToRgba(theme.colors.background.main, 0.7)};
  border-radius: 10px;
  /* overflow: hidden; */
`;

export const TR = styled.tr`
  padding: 0;
  margin: 0;
  width: 100%;
`;
export const TD = styled.td`
  border-bottom: 1px solid ${({ theme }) => hexToRgba(theme.colors.border.main, 0.3)};
  padding: 10px 20px;
`;
