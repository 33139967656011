import Icon from 'components/Icon';
import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: scroll;
`;

export const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  height: 48px;
  width: 48px;
  margin-right: 8px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Date = styled.span`
  margin-right: 8px;
`;

export const LinkIcon = styled(Icon)`
  cursor: pointer;
  svg {
    stroke: ${({ theme }) => theme.colors.icon.light};
    transition: stroke 0.15s linear;
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.primary.blue};
    }
  }
`;
