import Icon from 'components/Icon';
import styled from 'styled-components';

export const Link = styled.span`
  cursor: pointer;
  display: inline-flex;
  transition: color 0.15s linear;
  align-items: center;
  font-weight: 600;
  gap: 4px;
  width: max-content;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.blue};
  }
`;

export const DiscordIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.white};
  width: 24px;
  height: 24px;

  & ${Link}:hover {
    color: ${({ theme }) => theme.colors.primary.blue};
  }
`;

export const Text = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
`;
