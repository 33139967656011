import IconComponent from 'components/Icon';
import styled from 'styled-components';

export const Wrapper = styled.div`
  /* margin-left: 80px; */
  background-color: ${({ theme }) => theme.colors.background.footer};
  padding: 40px 60px;
  margin-top: auto;
  padding-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 30px 10px;
    padding-bottom: 20px;
  }
`;

export const Contact = styled.div``;

export const Link = styled.span`
  color: ${({ theme }) => theme.colors.text.light};
  cursor: pointer;
  font-size: 12px;
  font-family: 'Montserrat';
  transition: color 0.15s linear, border-color 0.15s linear;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    padding: 0;
    text-align: center;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    gap: 8px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    flex-direction: column;
  }
`;

export const SectionTitle = styled.h4`
  font-size: 14px;
  letter-spacing: 4px;
  font-weight: bold;
  margin-bottom: 12px;
`;

export const SectionContent = styled.div`
  margin-bottom: 16px;
`;

export const Text = styled.div`
  line-height: 1.5;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.light};
  font-family: 'Montserrat';

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const EmailForm = styled.form`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    flex-direction: column;
    width: 100%;

    & > * {
      width: 100%;
    }
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Icon = styled(IconComponent)`
  width: 28px;
  height: 28px;
  cursor: pointer;
  svg {
    width: 28px;
    height: 28px;
    fill: ${({ theme }) => theme.colors.icon.light};
    transition: fill 0.15s linear;

    path {
      transition: fill 0.15s linear;
      fill: ${({ theme }) => theme.colors.icon.light};
    }
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary.main};

      path {
        fill: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }
`;

export const CertikSection = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  font-size: 14px;
  gap: 16px;
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-items: center;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LeftTopSection = styled.div`
  display: flex;
  gap: 44px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-items: center;
    justify-content: center;
  }
`;

export const CrisiumLogo = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 160px;
  height: 60px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;
