import _ from 'lodash';
import mockImgSrc from 'assets/images/imspace.png';
// import landerImgSrc from 'assets/images/lander.jpeg';
import solarPanelSrc from 'assets/images/solar2-v2.png';
import solar2PanelSrc from 'assets/images/solar-v3.png';
// import solarFrameSrc from 'assets/images/solar-frame.png';
// import solarPanelSrc from 'assets/images/solarPanel.png';
// import solarPanelSrc from 'assets/images/solarPanel.jpeg';
// import fuelTankSrc from 'assets/images/fuelTank2.png';
import fuelTankSrc from 'assets/images/tank-v2.png';
import fuelTank2Src from 'assets/images/tank2-v2.png';
// import fuelTankSrc from 'assets/images/fuelTank.jpeg';
// import landingSiteSrc from 'assets/images/moonSurface.jpg';
import legsSrc from 'assets/images/legs-v2.png';
import legs2Src from 'assets/images/legs-2-v2.png';
import coreSrc from 'assets/images/core-v2.png';
import core2Src from 'assets/images/core2-v2.png';
// import landingSiteSrc from 'assets/images/landingSite.jpeg';

export const MOCK_SALES = [
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Title 1',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '1',
    buyer: '0x03e2838238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Title 2',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '3',
    buyer: '0x03e28ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Title 3',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
  {
    id: _.uniqueId(),
    imgSrc: mockImgSrc,
    title: 'Some title',
    price: Math.floor(Math.random() * 100).toString(),
    tokenId: '4',
    buyer: '0x12328ef238492834823984938423',
    date: '2017-04-20T11:32:00.000-04:00',
  },
];

export const MOCK_ITEMS = [
  {
    title: 'Core',
    description: 'Core',
    imgSrc: coreSrc,
    owner: '0x0323323',
    id: '0',
    price: '100.00',
    category: 'core',
  },
  {
    title: 'Core',
    description: 'Core',
    imgSrc: core2Src,
    owner: '0x0323323',
    id: '10',
    price: '100.00',
    category: 'core',
  },
  {
    title: 'Solar panel',
    description: 'Additional solar panel',
    imgSrc: solarPanelSrc,
    owner: '0x0323323',
    id: '3',
    price: '44.00',
    category: 'attachment',
  },
  {
    title: 'Legs',
    description: 'Legs',
    imgSrc: legs2Src,
    owner: '0x0323323',
    id: '4',
    price: '70.00',
    category: 'attachment',
  },
  {
    title: 'Solar panel 2',
    description: 'Additional solar panel',
    imgSrc: solar2PanelSrc,
    owner: '0x0323323',
    id: '5',
    price: '44.00',
    category: 'attachment',
  },
  {
    title: 'Fuel tank',
    description: 'Additional tank',
    imgSrc: fuelTankSrc,
    owner: '0x0323323',
    id: '6',
    price: '70.00',
    category: 'attachment',
  },
  {
    title: 'Legs',
    description: 'Legs',
    imgSrc: legsSrc,
    owner: '0x0323323',
    id: '7',
    price: '70.00',
    category: 'attachment',
  },
  {
    title: 'Fuel tank 2',
    description: 'Additional tank',
    imgSrc: fuelTank2Src,
    owner: '0x0323323',
    id: '8',
    price: '70.00',
    category: 'attachment',
  },
];
