import crisiumLogo from 'assets/images/crisiumLogo2.svg';
import imLogo from 'assets/images/imLogo.png';
import * as S from './About.styles';

const About = () => {
  return (
    <S.Wrapper>
      <S.Section>
        <S.SectionTitle>ABOUT THE MISSION</S.SectionTitle>
        <S.Text>
          Intuitive Machines is bringing America back to the Moon for the first time in 50 years. Intuitive
          Machines’s Nova-C intends to be the 1st lunar lander to softly touchdown on the Moon since Apollo 17
          in 1972, more than 50 years ago.
          <br />
          On November 29, 2018, NASA awarded Intuitive Machines its first contract to the Moon to deliver five
          payloads to the lunar surface, as part of NASA’s Commercial Lunar Payload Services (CLPS)
          initiative. The first mission is scheduled to launch in Q4 of this year on a SpaceX rocket launching
          from Kennedy Space Center Pad 39A, the same launch site of Apollo 17.
        </S.Text>
      </S.Section>
      <S.Section>
        <S.SectionTitle>ABOUT INTUITIVE MACHINES</S.SectionTitle>
        <S.Text>
          Intuitive Machines is a premier American provider of space services and aerospace technologies,
          headquartered in Houston, Texas. Its Nova-C lunar lander is based on a decade's worth of engineering
          development, first as NASA's Project M, then as Project Morpheus. The Nova-C has 130kg of landed
          mass payload capability, and utilizes a launch trajectory that enables a three-to-six-day lunar
          transit. It supports NASA’s CLPS missions, a key part of NASA’s Artemis lunar exploration efforts.
        </S.Text>
      </S.Section>
      <S.Section>
        <S.SectionTitle>ABOUT THE COLLABORATION</S.SectionTitle>
        <S.Text>
          This collaboration mark’s Crisium and Intuitive Machines’s first step into the world of web3
          together.
          <br />
          The goal for Crisium and Intuitive Machines's partnership is the democratization of space. Web3 has
          the unique capability to reach around the world and bring everyone together in celebration of this
          historic achievement for humanity. Along the way, Crisium and Intuitive Machines want to reward,
          educate and captivate everyone who participates.
          <br />
          Together, Crisium and Intuitive Machines are bringing space and lunar exploration to the masses.
        </S.Text>

        <S.LogoWrapper>
          <S.Logo src={imLogo} alt="imLogo" />
          <S.Logo src={crisiumLogo} alt="crisiumlogo" />
        </S.LogoWrapper>
      </S.Section>
    </S.Wrapper>
  );
};

export default About;
