import { useAccount, useConnect, useSwitchNetwork, chain as chainInfo, useNetwork } from 'wagmi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from 'components/Button';
import Modal, { ModalActions, ModalContent, ModalTitle } from 'components/Modal';
import metamaskSrc from 'assets/images/metamask.svg';
// import walletconnectSrc from 'assets/images/walletconnect.svg';
import coinbaseSrc from 'assets/images/coinbase.png';

import * as S from './WalletProviderModal.styles';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
}

const WALLETS = [
  {
    label: 'MetaMask',
    imageSrc: metamaskSrc,
  },
  // {
  //   label: 'WalletConnect',
  //   imageSrc: walletconnectSrc,
  // },
  {
    label: 'Coinbase Wallet',
    imageSrc: coinbaseSrc,
  },
];

const WalletProviderModal = ({ isOpen = false, onClose }: Props) => {
  const { connect, connectors, error } = useConnect();
  const { address } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork({ chainId: chainInfo.polygon.id });
  const { chain, chains } = useNetwork();

  useEffect(() => {
    !!address && onClose();
  }, [address, onClose]);

  useEffect(() => {
    error && toast.error(error.message);
  }, [error]);

  useEffect(() => {
    const isValidChain = !!chains.find((ch) => ch.id === chain?.id);

    if (!isValidChain) {
      switchNetworkAsync?.(chainInfo.polygon.id);
    }
  }, [chain, switchNetworkAsync, chains]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Connect your wallet</ModalTitle>
      <ModalContent>
        {WALLETS.map(({ label, imageSrc }) => {
          const connector = connectors.find(({ name }) => name === label);

          return (
            <S.Wallet key={connector?.id} onClick={() => connect({ connector })}>
              <S.LogoImg src={imageSrc} alt={label} />
              {label}

              {!connector?.ready && ' (unsupported)'}
            </S.Wallet>
          );
        })}
      </ModalContent>
      <ModalActions>
        <Button variant="outline" onClick={onClose}>
          Close
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default WalletProviderModal;
