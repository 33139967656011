import styled from 'styled-components';
import digitalGround from 'assets/images/home/digitalGround.png';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 60px 0 60px 300px;
  width: 100%;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    padding: 60px 0 60px 200px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding: 60px 0 60px 180px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 60px 0;
  }
`;

export const WrapperInner = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 1400px;
  align-items: center;
  max-width: 1600px;
  gap: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.largest}px) {
    width: 100%;
    max-width: 1600px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column-reverse;
  }
`;

export const BgImage = styled.div`
  position: absolute;
  background-image: url(${digitalGround});
  background-size: cover;
  display: flex;
  opacity: 0.2;
  top: 0;
  left: -40px;
  right: -40px;
  bottom: -40px;
  z-index: -1;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    bottom: -20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    right: -20px;
  }
`;

export const Content = styled.div`
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex: 2;
  }
`;

export const Imagery = styled.div`
  flex: 1;
`;

export const SectionTitle = styled.div`
  text-align: center;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 16px;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 26px;
    text-align: center;
  }
`;

export const TicketImage = styled.img`
  max-width: 600px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    max-width: 500px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 400px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 280px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 320px;
  }
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  max-width: 600px;
  text-align: left;
  margin-bottom: 32px;
  line-height: 1.5;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: center;
    margin-bottom: 24px;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    justify-content: center;
  }
`;
