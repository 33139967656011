import { IconProps } from '../types';

const CrisiumIcon = ({ width = 16, height = 24, ...props }: IconProps) => {
  return (
    <svg
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <g clipPath="url(#clip0_305_1135)">
        <path d="M0.836447 11.4141C0.360382 10.6442 0.12072 9.75239 0.146966 8.84848C0.132471 7.98406 0.325401 7.12869 0.709704 6.35354C1.08619 5.61544 1.621 4.96886 2.27624 4.4596C3.00029 3.91561 3.81815 3.50822 4.68942 3.25758C5.67687 2.96773 6.70187 2.82477 7.73125 2.83333C8.28928 2.83289 8.84664 2.87171 9.39918 2.94949C10.9201 1.91414 12.2737 0.691919 13.3333 0C11.8124 1.43434 10.0532 3.65152 8.33961 7.16162C8.17738 7.16162 8.01008 7.13636 7.83264 7.13636C7.19813 7.08248 6.56243 7.22537 6.01262 7.54545C5.82862 7.63647 5.67671 7.78101 5.57701 7.95995C5.4773 8.13888 5.43452 8.34377 5.45431 8.54747C5.47409 8.75117 5.55552 8.94408 5.68782 9.10065C5.82011 9.25722 5.99702 9.37007 6.19513 9.42424C6.50488 9.58425 6.82304 9.72756 7.14823 9.85353C6.75786 10.8283 6.36749 11.8737 5.99741 13.0202C5.79969 13.5859 5.58676 14.1364 5.36369 14.6869L4.85672 14.4848C4.03941 14.1572 3.26352 13.7351 2.54494 13.2273C1.8647 12.7368 1.2848 12.1214 0.836447 11.4141Z" />
        <path d="M0.288876 23.9952C0.81157 22.979 1.27866 21.9354 1.68811 20.8689C1.27078 20.4835 0.916667 20.0353 0.638683 19.5406C0.203762 18.7706 -0.0167659 17.8988 -9.70258e-05 17.0154V16.7881H4.47645C3.2861 19.3033 1.8853 21.7141 0.288876 23.9952Z" />
        <path d="M16.0001 17.2174C16.0213 18.0607 15.8301 18.8959 15.444 19.6466C15.0579 20.3974 14.4891 21.0399 13.7897 21.5154C13.0467 22.0219 12.2193 22.393 11.3461 22.6114C10.3391 22.8675 9.30305 22.9931 8.26373 22.9851C7.16446 22.9916 6.0689 22.8575 5.00391 22.5861C4.30589 22.4128 3.63294 22.1514 3.00137 21.8083C2.14459 22.6114 1.11544 23.4245 0.593262 23.8285C2.13008 21.5941 3.48627 19.2416 4.64903 16.7932H4.84168V16.9194C4.85076 17.1278 4.91935 17.3293 5.03939 17.5002C5.19673 17.7313 5.40042 17.9273 5.63762 18.076C5.95253 18.2693 6.29413 18.4156 6.65156 18.5103C7.1338 18.6309 7.6297 18.6886 8.12685 18.6821C8.79584 18.7392 9.46738 18.6061 10.0635 18.2982C10.2059 18.2129 10.3261 18.0952 10.4142 17.9547C10.5023 17.8143 10.5559 17.6551 10.5704 17.4901C10.5611 17.2655 10.4925 17.0472 10.3714 16.8575C10.2504 16.6677 10.0812 16.5131 9.88097 16.4093C9.06936 15.9855 8.22095 15.6356 7.34611 15.3639C6.77324 15.1821 6.19529 14.98 5.59199 14.7528C5.82013 14.2023 6.03306 13.6467 6.23078 13.0811C6.60087 11.9447 6.99123 10.8942 7.3816 9.91943C7.7669 10.0709 8.22317 10.2427 8.74535 10.4245C9.40949 10.6568 10.1699 10.9295 11.0166 11.278C11.8599 11.618 12.6611 12.0535 13.4044 12.576C14.1393 13.0942 14.7718 13.7431 15.2701 14.4901C15.7859 15.3042 16.0406 16.2555 16.0001 17.2174Z" />
        <path d="M13.4297 0.191895C12.7204 1.2346 12.1159 2.34432 11.6249 3.50503C12.2796 3.75099 12.8945 4.09132 13.45 4.51513C14.1437 5.04591 14.7143 5.71946 15.123 6.48987C15.5393 7.29152 15.7501 8.18335 15.7364 9.08583V9.36361H10.8644V9.19189C10.861 8.93964 10.7967 8.69191 10.6769 8.46967C10.5434 8.20727 10.3529 7.9778 10.1192 7.79795C9.84147 7.5748 9.52541 7.40368 9.18637 7.2929C8.98533 7.22578 8.77818 7.17841 8.56787 7.15149C9.76715 4.56605 11.4127 2.21043 13.4297 0.191895Z" />
      </g>
      <defs>
        <clipPath id="clip0_305_1135">
          <rect width="16" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CrisiumIcon;
