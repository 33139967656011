import Icon from 'components/Icon';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';

const commonOptionCss = css`
  font-weight: 600;
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SelectedOption = styled.div`
  ${commonOptionCss}
  background-color: transparent;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 20px;
  justify-content: space-between;
  transition: border-color 0.15s linear;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
  }
`;

export const ArrowDownIcon = styled(Icon)`
  svg {
    stroke: ${({ theme }) => theme.colors.icon.light};
    transition: stroke 0.15s linear;
  }

  ${SelectedOption}:hover & {
    svg {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const OptionList = styled.ul`
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  width: 100%;
  border-radius: 10px;
  padding: 10px 0;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.background.main};
  right: 0;
  z-index: 1;

  &:focus {
    outline: none;
  }
`;

export const Option = styled.li<{ selected?: boolean }>`
  ${commonOptionCss}
  list-style: none;
  height: 30px;
  padding: 0 20px;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${({ theme }) => lighten(0.04, theme.colors.background.main)};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => lighten(0.13, theme.colors.primary.main)};
    `}
`;

export const ListBox = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.main};
  border-radius: 10px;
`;
