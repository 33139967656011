import styled from 'styled-components';

import Button from 'components/Button';
import Card from 'components/Card';

export const BuyButton = styled(Button)`
  width: 100%;
  display: none;
`;

export const ItemInfoWrapper = styled.div``;

export const MarketCard = styled(Card)`
  height: 420px;

  .content {
    position: absolute;
    bottom: 0;
    transition: height 0.15s linear;
    background-color: ${({ theme }) => theme.colors.background.card};
    border-radius: 0 0 8px 8px;
    height: 154px;
    overflow: hidden;
  }

  .desc {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    .content {
      height: 206px;
    }
    ${BuyButton} {
      display: block;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 360px;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.white};
  overflow: hidden;
  margin-right: 10px;
`;

export const Username = styled.div`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: normal;
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary.blue};
`;
