import { Tab } from '@headlessui/react';
import { Fragment } from 'react';

import * as S from './Tabs.styles';

interface Props<T> {
  value: T;
  className?: string;
  tabs: { label: string; value: T; disabled?: boolean }[];
  onTabChange: (value: T) => void;
}

const Tabs = <T extends string>({ tabs, className, value: activeTab, onTabChange }: Props<T>) => {
  const selectedIndex = tabs.findIndex(({ value }) => value === activeTab);

  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={(index) => onTabChange(tabs[index].value)}
      vertical={false}
    >
      <Tab.List as={S.TabList} className={className}>
        {tabs.map(({ label, value, disabled }) => (
          <Tab as={Fragment} key={value}>
            {({ selected }) => (
              <S.Tab selected={selected} disabled={disabled} className="tab-item">
                {label}
              </S.Tab>
            )}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

export default Tabs;
