import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SectionTitle = styled.div`
  text-align: left;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 14px;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 26px;
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-family: 'Montserrat';
  text-align: center;
  color: ${({ theme }) => theme.colors.text.light};
  max-width: 1200px;
  margin-bottom: 36px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 40px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    gap: 24px;
  }
`;

export const Logo = styled.img`
  width: 260px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 220px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 160px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    width: 110px;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 60px;
`;
