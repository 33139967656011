import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState } from 'react';

import { MOCK_ITEMS } from 'pages/Marketplace/Marketplace.constants';
import mockAvatar from 'assets/images/mockAvatar.png';
import H2 from 'components/H2';
import Panel from 'components/Panel';
import CopyToClipboard from 'components/CopyToClipboard';
import OfferModal from 'components/OfferModal';

import * as S from './MarketplaceItem.styles';
import CheckoutModal from 'components/CheckoutModal';
import { constants, ethers } from 'ethers';
import { useBridgeContract } from 'hooks/useBacked20Contract';
import { useLocalStorage } from 'react-use';

export interface MarketItem {
  title: string;
  description: string;
  imgSrc: string;
  owner: string;
  id: string;
  price: string;
  category: string;
}

const MarketplaceItem = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const contract = useBridgeContract();

  const [items = [], setItems] = useLocalStorage<MarketItem[]>('marketplace-items');
  const [purchased, setPurchased] = useLocalStorage<MarketItem[]>('purchased-items');
  // const [fromMarketItem, setFromMarketItem] = useLocalStorage<boolean>('from-market-items');

  const item = MOCK_ITEMS.find((i) => i.id === id);
  console.log('params', item);

  if (!item) {
    return null;
  }

  const { title, imgSrc, owner, description, price } = item;

  const details = [
    { key: 'Contract Address', value: <CopyToClipboard str="0x0d3434839483984394934" maxChars={12} /> },
    { key: 'Token ID', value: id },
    { key: 'Token Standard', value: 'ERC20' },
    { key: 'Network', value: 'Polygon' },
    { key: 'Creator Fees', value: '8%' },
    { key: 'Owner', value: <CopyToClipboard str={owner} maxChars={12} /> },
  ];

  const handleBuy = () => {
    setShowCheckoutModal(true);
  };

  const handleOffer = () => {
    setShowOfferModal(true);
  };

  const handleSubmitOffer = () => {
    toast.success('You successfully made an offer');
    setShowOfferModal(false);
  };

  const handleSubmitPurchse = async () => {
    // toast.success('You successfully bought an item');

    try {
      setLoading(true);
      console.log('contract?.approve', contract, constants.MaxUint256);
      const res = await contract?.bridgeMint(
        '0x2B8ad6DaDaD60C56005A057A21de0A49fb8D1c94',
        ethers.BigNumber.from('100').mul(ethers.BigNumber.from(10).pow(18)).toHexString(),
        `for-test-purposes-${Math.random()}`
      );

      console.log('res', res);

      toast.success('Transaction is pending');

      setTimeout(() => {
        setItems(items?.filter(({ id }) => id !== item.id));
        setPurchased([...(purchased || []), item]);
        setLoading(false);
        toast.success('You successfully bought an item');
        setShowCheckoutModal(false);

        navigate('/user');
      }, 6000);
    } catch (e: any) {
      console.log('error', e, e?.message);
      setLoading(false);
      toast.error(e?.message || 'Something went wrong');
    }
  };

  return (
    <S.Wrapper>
      <S.BreadcrumbsWrapper
        items={[{ label: 'Marketplace', onClick: () => navigate('/marketplace') }, { label: title }]}
      />
      <S.Content>
        <S.Image src={imgSrc} />

        <S.Info>
          <S.UserInfo>
            <S.UserAvatar src={mockAvatar} /> {owner}
          </S.UserInfo>

          <H2>{title}</H2>

          <S.Description>{description}</S.Description>

          <S.PriceWrapper>
            <S.PriceTitle>CURRENT PRICE</S.PriceTitle>
            <S.Price>{price}</S.Price>
          </S.PriceWrapper>

          <S.Actions>
            <S.ActionButton variant="gradient" onClick={handleBuy} loading={loading}>
              Buy now
            </S.ActionButton>
            <S.ActionButton variant="outline" onClick={handleOffer}>
              Make an offer
            </S.ActionButton>
          </S.Actions>
        </S.Info>

        <div>
          <Panel title="Details">
            <S.DetailsContent>
              {details.map(({ key, value }) => (
                <S.DetailsRow key={key}>
                  <S.DetailsKey>{key}</S.DetailsKey>
                  <S.DetailValue>{value}</S.DetailValue>
                </S.DetailsRow>
              ))}
            </S.DetailsContent>
          </Panel>
        </div>
        <div>
          <S.OffersPanel title="Offer">
            <S.HelperText>no offers</S.HelperText>
          </S.OffersPanel>
          <Panel title="History">
            <S.HelperText>no history</S.HelperText>
          </Panel>
        </div>
      </S.Content>

      <OfferModal
        isOpen={showOfferModal}
        onClose={() => setShowOfferModal(false)}
        onSubmit={handleSubmitOffer}
      />

      <CheckoutModal
        item={item}
        isOpen={showCheckoutModal}
        loading={loading}
        onClose={() => setShowCheckoutModal(false)}
        onConfirm={handleSubmitPurchse}
      />
    </S.Wrapper>
  );
};

export default MarketplaceItem;
