import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import styled, { ThemeProvider } from 'styled-components';
import { WagmiConfig } from 'wagmi';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';

import Marketplace from 'pages/Marketplace';
import Header from 'components/Header';
import Container from 'components/Container';
import Background from 'components/Background';
import MarketplaceItem from 'pages/MarketplaceItem';
// import Missions from 'pages/Missions';
import { client } from 'config/wagmi';
import { useThemeContext } from 'hooks/useThemeContext';
import { themeDark, themeLight } from 'config/themes';
// import MissionItem from 'pages/MissionItem';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
// import Staking from 'pages/Staking';
// import Stake from 'pages/Staking/components/Stake';
// import Unstake from 'pages/Staking/components/Unstake';
// import Claim from 'pages/Staking/components/Claim';
import Home from 'pages/Home';
import Footer from 'components/Footer';
import About from 'pages/About';
import Faq from 'pages/Faq';
// import News from 'pages/News';
// import NewsItem from 'pages/NewsItem';
import Sidebar from 'components/Sidebar';
import { useEffect } from 'react';
import { Terms } from 'pages/Terms';
// import { AccountDrawer } from 'components/AccountDrawer';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  margin-left: 80px;
  min-height: 100vh;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(100% - 60px);
    margin-left: 60px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    margin-left: 0;
  }
`;

const queryClient = new QueryClient();

function App({ history }: any) {
  const location = useLocation();

  console.log('history', location.pathname);

  const { isLightTheme } = useThemeContext();
  const theme = isLightTheme ? themeLight : themeDark;

  const noPadding = location.pathname === '/missions';

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <WagmiConfig client={client}>
          <div style={{ display: 'flex' }}>
            <Sidebar />
            <Content>
              <Background>
                <Header />

                <Container noPadding={noPadding}>
                  <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/home" element={<Navigate to="/" replace />} />
                    <Route path="/terms" element={<Terms />} />

                    <Route path="/marketplace" element={<Marketplace />}></Route>
                    <Route path="/marketplace/:id" element={<MarketplaceItem />}></Route>
                    {/* <Route path="/missions" element={<Missions />}></Route> */}
                    {/* <Route path="/missions/:id" element={<MissionItem />}></Route> */}
                    {/* <Route path="/staking" element={<Staking />}>
                      <Route path="stake" element={<Stake />} />
                      <Route path="unstake" element={<Unstake />} />
                      <Route path="claim" element={<Claim />} />
                    </Route> */}
                    <Route path="/user" element={<User />}></Route>
                    <Route path="/user/:address?" element={<User />}></Route>
                    <Route path="/about" element={<About />}></Route>
                    <Route path="/faq" element={<Faq />}></Route>
                    {/* <Route path="/news" element={<News />}></Route> */}
                    {/* <Route path="/news/:id" element={<NewsItem />}></Route> */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Container>
              </Background>
              <Footer />
              {/* <AccountDrawer /> */}
            </Content>
          </div>
        </WagmiConfig>
        <ToastContainer theme="colored" />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
